import moment from 'moment';

function formatNumberThousandSpacing(num) {
    if (typeof num === 'number') return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    else return num
}

function dividing(value, divider) {
    if (value === 0) return 0
    if (value === null || value === undefined) return null
    if (divider === null || divider === undefined) return null
    if (typeof value !== 'number') return null
    if (typeof divider !== 'number') return null
    return value / divider
}

function roundTwoDecimals(value) {
    if (value === 0) return 0
    if (!value) return null
    if (typeof value !== 'number') return null
    return Math.round((value + Number.EPSILON) * 100) / 100
}

function enumDaysBetweenDates(startDate, endDate) {
    const start = moment(startDate)
    const end = moment(endDate)
	let now = start.clone()
	const dates = [];
	while (now.isSameOrBefore(end)) {
        dates.push(now.toDate());
        now.add(1, 'days');
    }
	return dates;
};

function relDiff(a, b) {
    if (a === null || b === null) return 0
    if (a === 0 || b === 0) return 0
    if (isNaN(a) || isNaN(b)) return 0

    let percentage = 100 * Math.abs(a / b);

    percentage = percentage - 100
    percentage = Math.round(percentage * 100) / 100

    return percentage
}

function everyNminutes(n) {
	var result = [];
	for (var hours = 0; hours < 24; hours++) {
		for (var minutes = 0; minutes < 60; minutes = minutes + n) {
			var h = '';
			var m = '';
			hours < 10 ? h = '0' + hours : h = hours;
			minutes < 10 ? 	m = '0' + minutes : m = minutes
			result.push(h + ':' + m);
		}
	}
	return result;
};

function enumerateQhBetweenDates(startDate, endDate) {
	const now = startDate.clone()
	const dates = [];
	const times = everyNminutes(15)
	while (now.isSameOrBefore(endDate)) {
		times.map(time => {
			const hourMin = time.split(':')
			const dateTime = now.clone().set({ hour: hourMin[0], minute: hourMin[1], seconds: 0, milliseconds: 0 })
			dates.push(dateTime);
		})
		now.add(1, 'days');
	}
	return dates;
};

function dateString(date) {
    const year = date.getUTCFullYear()
    const month = date.getUTCMonth() + 1
    const day = date.getUTCDate()
    return `${year}-${month}-${day}`
}

const utils = {
    formatNumberThousandSpacing,
    dividing,
    roundTwoDecimals,
    enumDaysBetweenDates,
    relDiff,
    enumerateQhBetweenDates,
    dateString
}

export {
    formatNumberThousandSpacing,
    dividing,
    roundTwoDecimals,
    enumDaysBetweenDates,
    relDiff,
    enumerateQhBetweenDates,
    dateString
}

export default utils