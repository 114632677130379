import React, { useContext, useState, createContext, useEffect, useCallback } from 'react'

import clientsController from "../controllers/clients/controller";
//import { useCustomer } from './Context';
import { useCustomer } from './CustomerContext';
import { ClientType } from '../utils/types'

const ClientsContext = createContext<ClientType[]>([]);

export function useClients() {
    return useContext(ClientsContext)
}

interface ClientsContextProviderProps {
    children: React.ReactNode;
}

export const ClientsContextProvider: React.FC<ClientsContextProviderProps> = ({ children }) => {

    const currentCustomer = useCustomer();
    /*
    useEffect(() => {

        async function getList(customerId: string) {
            const clients = await clientsController.getList(customerId)
        }

        
        if (currentCustomer) {
            getList(currentCustomer.id)
        }
    }, [currentCustomer])
    */

    return (
    <ClientsContext.Provider value={[]}>
        {children}
    </ClientsContext.Provider>
    )

}