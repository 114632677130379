// IMPORT -- react
import { FC, useState, useEffect } from 'react';
// IMPORT -- third party
import { Tooltip } from '@mui/material';
import { twMerge } from 'tailwind-merge';
// IMPORT -- icons
import { MdOutlineEnergySavingsLeaf } from 'react-icons/md';
import { WiSmoke } from "react-icons/wi";
import { SiGumtree } from "react-icons/si";
import { GiChimney } from "react-icons/gi";
// IMPORT -- project components
import { useSocket } from '../../../store/SocketContext';
import { useCleenEnvironment } from '../../../store/DashboardContext';
import { useCustomer } from '../../../store/CustomerContext';
import { energyFormat, weightFormat, formatNumberThousandSpacing } from '../../../utils/formatters';
import LoadingOverlay from '../../../components/LoadingOverlay';

const Environment: FC = () => {

    const customer = useCustomer();
    const socket = useSocket();
    const context = useCleenEnvironment();
    const contextData = context.data;
    const contextLoading = context.loading;
    const contextError = context.error;

    const [refresh, setRefresh] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [valueEnergy, setValueEnergy] = useState<number>(0);
    const [valueCo, setValueCo] = useState<number>(0);
    const [valueTree, setValueTree] = useState<number>(0);
    const [statusColor, setStatusColor] = useState<string>('bg-green-500');

    useEffect(() => {

        let subscribedCustomerId: string;

        async function statusBlink() {
            setStatusColor('bg-green-400');
            setTimeout(() => setStatusColor('bg-green-500'), 100);
        };

        function subscribe(customerId: string) {
            subscribedCustomerId = customerId;
            socket.on(`customer-energy-lifetime:${subscribedCustomerId}`, (message: string) => {
                if (message) {
                    setValueEnergy(parseFloat(message));
                    statusBlink();
                }
            });
        };

        if (customer && fetched && socket) subscribe(customer.id);

        return () => {
            socket.off(`customer-energy-lifetime:${subscribedCustomerId}`);
        };

    }, [customer, fetched, socket]);

    useEffect(() => {
        if (!contextData) setFetched(false);
        if (contextLoading) setRefresh(true);
        if (!contextLoading && contextData) {
            setValueEnergy(contextData.energy);
            setRefresh(false);
            setFetched(true);
        };
    }, [contextData, contextLoading, contextError]);

    useEffect(() => {
        setValueCo(valueEnergy ? (valueEnergy / 1000 * 0.475) : 0);
        setValueTree(valueEnergy ? (valueEnergy / 39000) : 0);
    }, [valueEnergy]);

    return (
        <div className='relative flex flex-col bg-white rounded-lg border-[1px] border-gray-200 shadow-sm p-4 gap-2'>
            {(refresh || !fetched) && <LoadingOverlay transparent={fetched} />}
            <div className='flex flex-col gap-1'>
                <div className='flex flex-row justify-start items-center gap-2 '>
                    <div className='relative flex justify-center items-center bg-green-500 p-2  rounded-full'>
                        <MdOutlineEnergySavingsLeaf className='text-2xl text-white' />
                        <div className='absolute top-0 left-0 w-[100%] h-[100%] spin-5000'>
                            <div className={twMerge('absolute top-0 left-[2px] w-2 h-2 rounded-full border-[1px] border-white', statusColor)} />
                        </div>
                    </div>
                    <span className='text-4xl text-green-500'>{energyFormat(valueEnergy).value} {energyFormat(valueEnergy).unit}</span>
                </div>
                <div className='flex flex-row justify-start items-center'>
                    <span className='text-sm text-gray-400 font-light'>összes megtermelt zöld energia</span>
                </div>
            </div>
            <div className='flex flex-col gap-1'>
                <div className='flex flex-row justify-between items-center gap-2'>
                    <Tooltip title={`Környezeti terhelés csökkent ${weightFormat(valueCo).value} ${weightFormat(valueCo).unit} megspórolt Co2 kibocsátással.`}>
                        <div className='flex felx-row gap-2 justify-start items-center cursor-help'>
                            <GiChimney className='w-4 h-4 text-green-500' />
                            <span className='text-sm font-semibold text-green-500'>{weightFormat(valueCo).value} {weightFormat(valueCo).unit}</span>
                        </div>
                    </Tooltip>
                    <Tooltip title={`Környezeti terhelés csökkennése ${formatNumberThousandSpacing(valueTree)} ki nem vágott fával egyenértékű.`}>
                    <div className='flex felx-row gap-2 cursor-help'>
                        <span className='text-sm font-semibold text-green-500'>{formatNumberThousandSpacing(valueTree)} db</span>
                        <SiGumtree className='w-4 h-4 text-green-500' />
                    </div>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default Environment;
