// IMPORT -- react
import { useContext, useState, createContext, useEffect, useCallback, ReactNode, FC } from 'react';
// IMPORT -- project
import { UserType } from '../utils/types';
import api from '../controllers/api/controller';

const ConnectLoadingContext = createContext<boolean>(true);
const UserContext = createContext<UserType>(null!);
const LoginContext = createContext<(email: string, password: string, remember: boolean) => Promise<UserType>>(null!);
const LogoutContext = createContext<() => void>(() => { });
const CurrentCustomerContext = createContext<string>(null!);
const SetCurrentCustomerContext = createContext<React.Dispatch<React.SetStateAction<string>>>(() => { });

export function useConnectLoading() {
    return useContext(ConnectLoadingContext);
}

export function useUser() {
    return useContext(UserContext);
}

export function useLogin() {
    return useContext(LoginContext);
}

export function useLogout() {
    return useContext(LogoutContext);
}

export function useCurrentCustomer() {
    return useContext(CurrentCustomerContext);
}

export function useSetCurrentCustomer() {
    return useContext(SetCurrentCustomerContext);
}


interface UserContextProviderProps {
    children: ReactNode;
}

const UserContextProvider: FC<UserContextProviderProps> = ({ children }) => {

    const [connectLoading, setConnectLoading] = useState<boolean>(true);
    const [user, setUser] = useState<UserType>(null!);
    const [currentCustomer, setCurrentCustomer] = useState<string>(null!);

    useEffect(() => {

        async function passportLogin(token: string) {
            try {
                const response = await api.authentication.passport(token)
                sessionStorage.setItem('access', response._access);
                if (response.customers.length > 0) setCurrentCustomer(response.customers[0].id);
                if (response._paccess) localStorage.setItem('_paccess', response._paccess);
                setUser(response);
                setConnectLoading(false);
            } catch (error) {
                localStorage.removeItem('_paccess');
                setConnectLoading(false);
            }
        }

        const passport = localStorage.getItem('_paccess')
        if (passport) {
            passportLogin(passport)
        } else {
            setConnectLoading(false)
        }

    }, [])


    async function login(email: string, password: string, remember: boolean): Promise<UserType> {
        return new Promise(async (resolve, reject) => {

            try {
                const response = await api.authentication.login(email, password, remember)
                sessionStorage.setItem('access', response._access);
                if (response.customers.length > 0) {
                    setCurrentCustomer(response.customers[0].id)
                }
                if (response._paccess) {
                    localStorage.setItem('_paccess', response._paccess);
                }
                setUser(response);
                console.log(response)
                resolve(response)
            } catch (error) {
                reject(error)
            }

        })
    }

    async function logout() {
        localStorage.clear();
        setUser(null!);
        setCurrentCustomer(null!);
    }

    return (
        <>
            <ConnectLoadingContext.Provider value={connectLoading}>
                <UserContext.Provider value={user}>
                    <LoginContext.Provider value={login}>
                        <LogoutContext.Provider value={logout} >
                            <CurrentCustomerContext.Provider value={currentCustomer}>
                                <SetCurrentCustomerContext.Provider value={setCurrentCustomer}>
                                    {children}
                                </SetCurrentCustomerContext.Provider>
                            </CurrentCustomerContext.Provider>
                        </LogoutContext.Provider>
                    </LoginContext.Provider>
                </UserContext.Provider>
            </ConnectLoadingContext.Provider>
        </>
    );
}

export default UserContextProvider;