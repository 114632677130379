// react
import React from "react";

// project components
import Editor from './Editor';

const StatisticsDefault = () => {
    return <Editor />
}

export default StatisticsDefault
