import api from './api';

async function query(query: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await api.post(`query`, query);
            resolve(data)
        } catch(error) {
            return reject(error)
        }
    })
}

const controller = { query }
export { controller as default, query }