// *** IMPORT ***
// -- assets
import './assets/styles/styles.css';
import './assets/fonts/game_of_squids.ttf';
import 'react-datepicker/dist/react-datepicker.css';
// -- project
import { useUser } from './store/UserContext';
import LoginPage from "./pages/LoginPage";
import MainPage from './pages/MainPage';

const App = () => {
  const user = useUser();
  if (user) return <MainPage />
  return <LoginPage />
}

export default App;
