import React, { useContext, useState, createContext, useEffect, useCallback } from 'react'
import moment from 'moment';

import apiController from '../controllers/api'

import { useAuth } from './AuthContext';

const NavigationContext = createContext()
const SetNavigationContext = createContext()
const MenuContext = createContext()
const CustomerContext = createContext()
const DashboardContext = createContext()
const StyleThemeContext = createContext()

export function useNavigation() {
    return useContext(NavigationContext)
}

export function useSetNavigation() {
    return useContext(SetNavigationContext)
}

export function useMenu() {
    return useContext(MenuContext)
}

export function useCustomer() {
    return useContext(CustomerContext)
}

export function useDashboard() {
    return useContext(DashboardContext)
}

export function useStyleTheme() {
    return useContext(StyleThemeContext)
}

export function ContextProvider({ children }) {

    const auth = useAuth()

    var currentDate = moment();

    var weekStart = currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD');
    var weekEnd = currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD');

    const navigationInitState = 'dashboard'

    const styleThemeInitState = {
        colors: {
            primary: '#1976d2',
            secondary: '#d32f2f'
        }
    }

    const [navigation, setNavigation] = useState(navigationInitState)

    const [dashboardCleenEnvironmentData, setDashboardCleenEnvironmentData] = useState({ loading: false, error: '', data: null })
    const [dashboardCardsData, setDashboardCardsData] = useState({ loading: false, error: '', data: null })
    const [dashboardDailyChartDate, setDashboardDailyChartDate] = useState(dateString(new Date()))
    const [dashboardDailyChartData, setDashboardDailyChartData] = useState({ loading: false, error: '', data: null })
    const [dashboardWeekMonthChartDate, setDashboardWeekMonthChartDate] = useState({ start: weekStart, end: weekEnd })
    const [dashboardWeekMonthChartSlot, setDashboardWeekMonthChartSlot] = useState('week')
    const [dashboardWeekMonthChartData, setDashboardWeekMonthChartData] = useState({ loading: false, error: '', data: null })
    const [dashboardCurrentPowerData, setDashboardCurrentPowerData] = useState({ loading: false, error: '', data: null })
    const [dashboardDevicesTableData, setDashboardDevicesTableData] = useState({ loading: false, error: '', data: null })

    const [styleTheme] = useState(styleThemeInitState)
    const [currentCustomer, setCurrentCustomer] = useState({ loading: false, error: '', data: null })

    const [dashboardLoading, setDashboardLoading] = useState(true)

    const menuInitState = [{ number: 1, type: 'menu', name: 'Áttekintés', module: 'dashboard' }]

    const [menu, setMenu] = useState(menuInitState)

    useEffect(() => {
        if(currentCustomer) {
            setMenu([
                { number: 1, type: 'menu', name: 'Áttekintés', module: 'dashboard' },
                { number: 2, type: 'divider' },
                { number: 3, type: 'menu', name: 'Eszközök', module: 'clients' },
                { number: 4, type: 'menu', name: 'Adat export', module: 'dataexport' },
                
                { number: 5, type: 'menu', name: 'Statisztika', module: 'statistics' },
                
                
                //{ number: 5, type: 'menu', name: 'Diagnosztika', module: 'diagnostic' }
                
            ])
        }
    }, [currentCustomer])

    const dashboardFunctions = {
        cleenEnvironment: {
            state: dashboardCleenEnvironmentData
        },
        devicesTable: {
            state: dashboardDevicesTableData
        },
        cards: {
            state: dashboardCardsData
        },
        dailyChart: {
            date: dashboardDailyChartDate,
            state: dashboardDailyChartData,
            setDate: setDashboardDailyChartDate
        },
        weekMonthChart: {
            date: dashboardWeekMonthChartDate,
            state: dashboardWeekMonthChartData,
            setDate: setDashboardWeekMonthChartDate,
            slot: dashboardWeekMonthChartSlot,
            setSlot: setDashboardWeekMonthChartSlot
        },
        currentPower: {
            state: dashboardCurrentPowerData
        },
        functions: {
            refreshDashboard: refreshDashboard
        },
        basic: {
            loading: dashboardLoading
        }
    }

    useEffect(() => {

        const loadingStates = [
            dashboardCleenEnvironmentData.loading,
            dashboardCardsData.loading,
            dashboardDailyChartData.loading,
            dashboardWeekMonthChartData.loading,
            dashboardCurrentPowerData.loading,
            dashboardDevicesTableData.loading
        ]

        if (loadingStates.every(e => e === false)) {
            setDashboardLoading(false)
        } else {
            setDashboardLoading(true)
        }

    }, [
        dashboardCleenEnvironmentData,
        dashboardCardsData,
        dashboardDailyChartData,
        dashboardWeekMonthChartData,
        dashboardCurrentPowerData,
        dashboardDevicesTableData
    ])

    const getDashboardCleenEnvironment = useCallback(async () => {
        if (auth.currentCustomerId) {
            setDashboardCleenEnvironmentData((prev) => { return { ...prev, loading: true, error: '' } })
            try {
                const datas = await apiController.getDashboardAllTimeProd(auth.currentCustomerId)
                setDashboardCleenEnvironmentData({ loading: false, error: '', data: datas })
            } catch (err) {
                setDashboardCleenEnvironmentData({ loading: false, error: err.message, data: null })
            }
        }
    }, [auth])

    const getDashboardCards = useCallback(async () => {
        if (auth.currentCustomerId) {
        setDashboardCardsData((prev) => { return { ...prev, loading: true, error: '' } })
        try {
            const datas = await apiController.getDashboardDayYearCards(auth.currentCustomerId)
            setDashboardCardsData({ loading: false, error: '', data: datas })
        } catch (err) {
            setDashboardCardsData({ loading: false, error: err.message, data: null })
        }
    }
    }, [auth])

    const getDashboardDailyChart = useCallback(async () => {
        if (auth.currentCustomerId) {
        setDashboardDailyChartData((prev) => { return { ...prev, loading: true, error: '' } })
        try {
            const datas = await apiController.getDashboardDailyChart(auth.currentCustomerId, dashboardDailyChartDate)
            setDashboardDailyChartData({ loading: false, error: '', data: datas })
        } catch (err) {
            setDashboardDailyChartData({ loading: false, error: err.message, data: null })
        }
    }
    }, [auth, dashboardDailyChartDate])

    const getDashboardWeekMonthChart = useCallback(async () => {
        if (auth.currentCustomerId) {
        setDashboardWeekMonthChartData((prev) => { return { ...prev, loading: true, error: '' } })
        try {
            const datas = await apiController.getDashboardConsProdChart(auth.currentCustomerId, dashboardWeekMonthChartDate.start, dashboardWeekMonthChartDate.end)
            setDashboardWeekMonthChartData({ loading: false, error: '', data: datas })
        } catch (err) {
            setDashboardWeekMonthChartData({ loading: false, error: err.message, data: null })
        }
    }
    }, [auth, dashboardWeekMonthChartDate])

    const getDashboardCurrent = useCallback(async () => {
        if (auth.currentCustomerId) {
        setDashboardCurrentPowerData((prev) => { return { ...prev, loading: true, error: '' } })
        try {
            const datas = await apiController.getDashboardCurrentStatus(auth.currentCustomerId)
            setDashboardCurrentPowerData({ loading: false, error: '', data: datas })
        } catch (err) {
            setDashboardCurrentPowerData({ loading: false, error: err.message, data: null })
        }
    }
    }, [auth])

    const getDashboardDevicesTable = useCallback(async () => {
        if (auth.currentCustomerId) {
        setDashboardDevicesTableData((prev) => { return { ...prev, loading: true, error: '' } })
        try {
            const datas = await apiController.getDashboardDevicesTable(auth.currentCustomerId)
            setDashboardDevicesTableData({ loading: false, error: '', data: datas })
        } catch (err) {
            setDashboardDevicesTableData({ loading: false, error: err.message, data: null })
        }
    }
    }, [auth])

    function refreshDashboard() {
        getDashboardCleenEnvironment()
        getDashboardCards()
        getDashboardDailyChart()
        getDashboardWeekMonthChart()
        getDashboardCurrent()
        getDashboardDevicesTable()
    }

    useEffect(() => {
        async function getCustomerData() {
            setCurrentCustomer((prev) => { return { ...prev, loading: true, error: '' } })
            const data = await apiController.getCurrentCustomer(auth.currentCustomerId)
            setCurrentCustomer({ loading: false, error: '', data: data })
        }
        if (auth.currentCustomerId) getCustomerData()
    }, [auth])

    useEffect(() => {
        getDashboardCleenEnvironment()
    }, [getDashboardCleenEnvironment])

    useEffect(() => {
        getDashboardCards()
    }, [getDashboardCards])

    useEffect(() => {
        getDashboardDailyChart()
    }, [getDashboardDailyChart])

    useEffect(() => {
        getDashboardWeekMonthChart()
    }, [getDashboardWeekMonthChart])

    useEffect(() => {
        getDashboardCurrent()
    }, [getDashboardCurrent])

    useEffect(() => {
        getDashboardDevicesTable()
    }, [getDashboardDevicesTable])

    return (
        <NavigationContext.Provider value={navigation}>
            <SetNavigationContext.Provider value={setNavigation}>
                <MenuContext.Provider value={menu}>
                    <CustomerContext.Provider value={currentCustomer}>
                        <DashboardContext.Provider value={dashboardFunctions}>
                            <StyleThemeContext.Provider value={styleTheme}>
                                {children}
                            </StyleThemeContext.Provider>
                        </DashboardContext.Provider>
                    </CustomerContext.Provider>
                </MenuContext.Provider>
            </SetNavigationContext.Provider>
        </NavigationContext.Provider>
    )
}

function dateString(date) {
    const year = date.getUTCFullYear()
    const month = date.getUTCMonth() + 1
    const day = date.getUTCDate()
    return `${year}-${month}-${day}`
}

export default ContextProvider