import React, { FC, useEffect, useState } from "react";
import { SourceItemType } from "../../types";
import { useCustomer } from "../../store/Context";
import apiController from "../../controllers/api";
import SourcesList from "./SourcesList";
import Diagnostic from "./Diagnostic";

const DiagnosticIndex: FC = () => {

    const customer = useCustomer().data;
    const [sources, setSources] = useState<SourceItemType[]>([]);
    const [sourceId, setSourceId] = useState<string | null>(null);

    useEffect(() => {
        async function fetch(customerId: string) {
            const response = await apiController.statistics.getProperties(customerId)
            setSources(response);
        }
        if (customer) fetch(customer.id);
    }, [customer]);

    const handleBackToList = () => {
        setSourceId(null)
    }

    
        if (!sourceId) return <SourcesList sources={sources} onSelect={setSourceId} />
        return <Diagnostic sourceId={sourceId} onSelectBack={handleBackToList} />
    
}

export default DiagnosticIndex;