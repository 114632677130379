// IMPORT -- project
import api from './api';
import { UserType } from '../../utils/types';
import axios from '../axios';

async function login(email: string, password: string, remember: boolean): Promise<UserType> {

    return new Promise(async (resolve, reject) => {

        try {
            const response = await axios.post('/api/login', {
                email: email,
                password: password,
                remember: remember,
                platform: 'WEB'
            })

            if (response.status !== 200) reject(new Error('Unauthorized'))

            resolve(response.data as UserType)

        } catch (error) {
            reject(error)
        }



    })

}

async function passport(token: string): Promise<UserType> {

    return new Promise(async (resolve, reject) => {

        try {
            const response = await axios.post('/api/passport', {
                passport: token,
                platform: 'WEB'
            })

            if (response.status !== 200) reject(new Error('Unauthorized'))

            resolve(response.data as UserType)

        } catch (error) {
            reject(error)
        }



    })

}

const controller = { login, passport }
export { controller as default, login, passport }