import { FC, useState } from 'react'

import moment, { Moment } from 'moment';

import { Tooltip, IconButton } from '@mui/material';

import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { DashboardWeekMonthChartDateType } from '../../../utils/types';

interface MonthStepperProps {
    setWeekMonthDate: (updatedDate: DashboardWeekMonthChartDateType) => void;
    weekMonthLoading: boolean;
}

const MonthStepper: FC<MonthStepperProps> = ({ setWeekMonthDate, weekMonthLoading }) => {

    const month = ["Január", "Február", "Március", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Október", "November", "December"];
    const current = moment().startOf('month')

    const [selected, setSelected] = useState(current.clone())

    const handleChangeMonth = (updatedMonth: Moment) => {
        const startDate = updatedMonth.startOf('month').format('YYYY-MM-DD');
        const endDate = updatedMonth.endOf('month').format('YYYY-MM-DD');
        setSelected(updatedMonth);
        setWeekMonthDate({ start: startDate, end: endDate });
    }

    const handleDecrementMonth = () => handleChangeMonth(selected.clone().subtract(1, 'months'));
    const handleIncrementMonth = () => handleChangeMonth(selected.clone().add(1, 'months'));
    const handleResetMonth = () => handleChangeMonth(current.clone());

    const selectedYear = selected.clone().format('YYYY')
    const selectedMonthNum = parseInt(selected.clone().format('M')) - 1

    return (
        <>
            <Tooltip title="Előző hónap" arrow>
                <span>
                    <IconButton size='small' onClick={() => handleDecrementMonth()} disabled={weekMonthLoading}>
                        <ChevronLeftIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Kiválasztott hónap" arrow>
                <span style={{ fontSize: '0.9rem', color: '#1976d2' }}>{selectedYear}. {month[selectedMonthNum]}</span>
            </Tooltip>
            <Tooltip title="Következő hónap" arrow>
                <span>
                    <IconButton size='small' onClick={() => handleIncrementMonth()} disabled={(selected.format('YYYY-M') === current.format('YYYY-M')) || weekMonthLoading}>
                        <ChevronRightIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Ugrás az aktuális hónapra" arrow>
                <span>
                    <IconButton size="small" color={"primary"} onClick={() => handleResetMonth()} disabled={(selected.format('YYYY-M') === current.format('YYYY-M')) || weekMonthLoading}>
                        <EventRepeatIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    )
}

export default MonthStepper;