// *** IMPORT ***
// -- react
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// -- project
//import AuthProvider from "./store/AuthContext";
//import RegisterPage from './pages/RegisterPage';
//import PasswordReminderPage from './pages/PasswordReminderPage';
import UserContextProvider from './store/UserContext';
import App from './App';
import Admin from './Admin';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Home = () => {
    return (
        <UserContextProvider>
                <App />
        </UserContextProvider>
    );
}

const AdminHome = () => {
    return (
        <UserContextProvider>
                <Admin />
        </UserContextProvider>
    );
}

root.render(
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Home />} />
            {/* 
            <Route path='/admin' element={<AdminHome />} />
            
            <Route path='/register/:token' element={<RegisterPage />} />
            <Route path='/resetpassword/:token' element={<RegisterPage />} />
            <Route path='/reminder' element={<PasswordReminderPage />} />
            */}
        </Routes>
    </BrowserRouter>
);
