// IMPORT -- react
import { FC } from 'react';

const NoCustomer: FC = () => {
    return (
        <div className='relative flex flex-col justify-center items-center bg-white rounded-lg border-[1px] border-gray-200 shadow-sm p-4 gap-2'>
            <span className='text-lg font-semibold text-center'>Ez a felhasználói fiók nincs hozzárendelve ügyfélhez.</span>
        </div>
    );
};

export default NoCustomer;