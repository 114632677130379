// IMPORT -- react
import { FC, useState, useEffect } from 'react';
// IMPORT -- third party
import { twMerge } from 'tailwind-merge';
// IMPORT -- icons
import { ImArrowUp, ImArrowDown } from 'react-icons/im';
// IMPORT -- project components
import { useSocket, useSocketConnected } from '../../../store/SocketContext';
import { useCustomer } from '../../../store/CustomerContext';
import { useCurrentPower } from '../../../store/DashboardContext';
import { powerFormat } from '../../../utils/formatters';
import LoadingOverlay from '../../../components/LoadingOverlay';
import ModuleErrorOverlay from '../../../components/ModuleErrorOverlay';

const PowerStatus: FC = () => {

    const socket = useSocket();
    const customer = useCustomer();
    const context = useCurrentPower();
    const contextData = context.data;
    const contextLoading = context.loading;
    const contextError = context.error;

    const [refresh, setRefresh] = useState<boolean>(false);
    const [fetched, setFetched] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false)
    const [valueProd, setValueProd] = useState<number>(0);
    const [valueCons, setValueCons] = useState<number>(0);
    const [valueProdPercent, setValueProdPercent] = useState<number>(0);
    const [valueConsPercent, setValueConsPercent] = useState<number>(0);
    const [valueResult, setValueResult] = useState<number>(0);
    const [statusColorProd, setStatusColorProd] = useState<string>('text-white');
    const [statusColorCons, setStatusColorCons] = useState<string>('text-white');

    useEffect(() => {

        let subscribedCustomerId: string;

        async function statusBlinkProd() {
            setStatusColorProd('text-green-300');
            setTimeout(() => setStatusColorProd('text-white'), 100);
        };

        async function statusBlinkCons() {
            setStatusColorCons('text-rose-300');
            setTimeout(() => setStatusColorCons('text-white'), 100);
        };

        function subscribe(customerId: string) {
            subscribedCustomerId = customerId;
            socket.on(`customer-power-production:${subscribedCustomerId}`, (message: string) => {
                if (message) {
                    setValueProd(parseFloat(message));
                    statusBlinkProd();
                }
            });
            socket.on(`customer-power-consumption:${subscribedCustomerId}`, (message: string) => {
                if (message) {
                    setValueCons(parseFloat(message));
                    statusBlinkCons();
                }
            });
        };

        if (customer && fetched && socket) subscribe(customer.id);

        return () => {
            socket.off(`customer-power-production:${subscribedCustomerId}`);
            socket.off(`customer-power-consumption:${subscribedCustomerId}`);
        };

    }, [customer, fetched, socket]);

    useEffect(() => {
        if (!contextData) setFetched(false);
        if (contextLoading) setRefresh(true);
        if (contextError) setError(true);
        if (!contextLoading && !contextError && contextData) {
            setValueProd(contextData.prodValue);
            setValueCons(contextData.consValue);
            let prodPercent = 100 * Math.abs(contextData.prodValue / contextData.prodMaxValue);
            prodPercent = Math.round(prodPercent);
            if (prodPercent > 100) prodPercent = 100;
            setValueProdPercent(prodPercent || 0);
            let consPercent;
            consPercent = 100 * Math.abs(contextData.consValue / contextData.consMaxValue);
            consPercent = Math.round(consPercent);
            if (consPercent > 100) consPercent = 100;
            setValueConsPercent(consPercent || 0);
            setValueResult(contextData.prodValue || 0 - contextData.consValue || 0);
            setError(false);
            setRefresh(false);
            setFetched(true);
        }
    }, [contextLoading, contextError, contextData]);

    useEffect(() => {
        if (fetched) setValueResult(valueProd - valueCons);
    }, [valueCons, valueProd, fetched]);

    return (
        <div className='relative flex flex-row bg-white border-[1px] border-gray-200 rounded-lg overflow-hidden'>
            {(refresh || !fetched) && <LoadingOverlay transparent={fetched} />}
            {error && <ModuleErrorOverlay />}
            <div className={twMerge('relative w-4 h-full bg-white', valueProd === 0 ? 'top-1' : 'animate-slide-up')}>
                <div className='absolute left-1 top-0 w-2 h-2 bg-green-500 rounded-full ' />
            </div>
            <div className='flex flex-col gap-1 w-full pt-3 pb-3 pl-1 pr-1'>
                <div className='flex felx-row justify-between items-center'>
                    <div className='flex flex-row justify-start items-center gap-2'>
                        <span className='text-sm text-gray-400 font-light'>Jelenlegi termelés</span>
                    </div>
                    <div className='flex flex-row justify-start items-center gap-2'>
                        <span className='text-sm text-gray-400 font-light'>Jelenlegi fogyasztás</span>
                    </div>
                </div>
                <div className='flex felx-row justify-between items-center'>
                    <div className='flex flex-row justify-start items-center gap-2'>
                        <div className='flex justify-center items-center p-2 rounded-full bg-green-500'>
                            <ImArrowUp className={twMerge(statusColorProd)} />
                        </div>
                        <div className='flex flex-col justify-center items-start'>
                            <span className='text-lg font-semibold text-green-500'>{powerFormat(valueProd).value} {powerFormat(valueProd).unit}</span>
                            <span className='text-sm font-semibold text-green-500'>{valueProdPercent} %</span>
                        </div>
                    </div>
                    <div className='flex flex-row justify-start items-center gap-2'>
                        <div className='flex flex-col justify-center items-end'>
                            <span className='text-lg font-semibold text-rose-500'>{powerFormat(valueCons).value} {powerFormat(valueCons).unit}</span>
                            <span className='text-sm font-semibold text-rose-500'>{valueConsPercent} %</span>
                        </div>
                        <div className='flex justify-center items-center p-2 rounded-full bg-rose-500'>
                            <ImArrowDown className={twMerge(statusColorCons)} />
                        </div>
                    </div>
                </div>
                <div className='flex flex-row justify-center items-center'>
                    <div>
                        <span className={twMerge(
                            'text-xl font-bold',
                            valueResult > 0 ? 'text-green-500' : '',
                            valueResult < 0 ? 'text-rose-500' : '',
                            valueResult === 0 ? 'text-blue-500' : '',
                        )}>
                            {powerFormat(valueResult, true).value} {powerFormat(valueResult).unit}
                        </span>
                    </div>
                </div>
            </div>
            <div className={twMerge('relative w-4 h-full bg-white ', valueCons === 0 ? 'top-1' : 'animate-slide-down')}>
                <div className='absolute left-1 top-0 w-2 h-2 bg-rose-500 rounded-full ' />
            </div>
        </div>
    );
};

export default PowerStatus;