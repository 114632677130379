// IMPORT -- react
import { FC, ReactNode, useContext, useState, createContext, useEffect } from 'react';
// IMPORT -- third party
import socketio, { Socket, connect } from 'socket.io-client';
// IMPORT -- project
import { useUser } from './UserContext';

const SocketContext = createContext<Socket>(socketio({ autoConnect: false }));
const SocketConnectedContext = createContext<boolean>(false);

const useSocket = () => useContext(SocketContext);
const useSocketConnected = () => useContext(SocketConnectedContext);

const SocketContextProvider: FC<{ children: ReactNode }> = ({ children }) => {

    const user = useUser();
    const [socket, setSocket] = useState<Socket>(socketio({ autoConnect: false }));
    const [connected, setConnected] = useState<boolean>(socket.connected);

    useEffect(() => {
        if (user) {
            const client = connect({
                extraHeaders: {
                    "_sid": user._sid,
                    "_saccess": user._saccess
                }
            });

            client.on('connect', () => {
                setSocket(client);
                setConnected(true);
            });

            client.on('reconnect', () => {
                setSocket(client);
                setConnected(true);
            });

            

            client.on('disconnect', () => {
                setSocket(client);
                setConnected(false);
            });
        }
    }, [user]);

    useEffect(() => {
        
    }, [socket.connected]);

    return (
        <SocketContext.Provider value={socket}>
            <SocketConnectedContext.Provider value={connected}>
                {children}
            </SocketConnectedContext.Provider>
        </SocketContext.Provider>
    )

}

export { SocketContextProvider as default, useSocket, useSocketConnected };
