import PropTypes from 'prop-types';

// material-ui
import { Box, Typography, Tooltip } from '@mui/material';

// assets
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const Comparison = ({ firstText, secondText, firstTooltip, secondTooltip, firstColor, secondColor }) => (
    <Box sx={{
        display: 'flex',
        justifyContent: 'flex-start',
    }}>
        <Tooltip arrow title={<Typography>{firstTooltip}</Typography>}>
            <Box
                sx={{
                    p: .5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderRadius: '0.4rem'
                }}>
                    <TrendingUpIcon style={{ fontSize: '1.5rem', color: firstColor }} />
                    <Typography style={{ fontSize: '1rem', color: firstColor, marginLeft: 2, cursor: 'pointer' }}>
                        <strong>{firstText}</strong>
                    </Typography>
            </Box>
        </Tooltip>
        
        <Tooltip arrow title={<Typography>{secondTooltip}</Typography>}>
            <Box
                sx={{
                    p: .5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderLeft: '2px solid #ccc'
                }}>
                    <TrendingDownIcon style={{ fontSize: '1.5rem', color: secondColor }} />
                    <Typography style={{ fontSize: '1rem', color: secondColor, marginLeft: 2, cursor: 'pointer' }}>
                        <strong>{secondText}</strong>
                    </Typography>
            </Box>
        </Tooltip>
    </Box>
);

Comparison.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    count: PropTypes.string,
    percentage: PropTypes.number,
    isLoss: PropTypes.bool,
    extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    labeltext: PropTypes.string,
    lasttext: PropTypes.string
};

Comparison.defaultProps = {
    color: 'primary'
};

export default Comparison;
