import api from './api';

async function getProperties(customerId: string) {
    return new Promise(async (resolve, reject) => {
        try {
            //const response = await apiPost('statistics/getProperties', { customerId: customerId })
            const response = await api.post('statistics/getProperties', { customerId: customerId })
            resolve(response)
        } catch (err) {
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

async function getData(collector: any, startDate: any, endDate: any, dataResolution: any, sumType: any) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await api.post('statistics/getData', { 
                collector,
                startDate: startDate,
                endDate: endDate,
                resolution: dataResolution,
                sum: sumType
            })
            resolve(response)
        } catch (err) {
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

const controller = { getProperties, getData }
export { controller as default, getProperties, getData }