// react
import { useContext, useState, createContext, useEffect, useCallback, ReactNode, FC } from 'react';

// project import
import { useAuth } from './AuthContext';
import { useCurrentCustomer } from './UserContext';
import { CustomerType } from '../utils/types';
import apiController from '../controllers/api/controller';

const CustomerContext = createContext<CustomerType | null>(null);
const CustomerLoadingContext = createContext<boolean>(true);
const CustomerErrorContext = createContext<boolean>(false);

export function useCustomer() {
    return useContext(CustomerContext);
}
export function useCustomerLoading() {
    return useContext(CustomerLoadingContext);
}
export function useCustomerError() {
    return useContext(CustomerErrorContext);
}

interface CustomerContextProviderProps {
    children: ReactNode;
}

const CustomerContextProvider: FC<CustomerContextProviderProps> = ({ children }) => {

    //const auth = useAuth()
    const currentCustomer = useCurrentCustomer()
    const [customer, setCustomer] = useState<CustomerType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);


    useEffect(() => {
        
        async function fetch(customerId: string) {
            setLoading(true)
            apiController.customer.getCustomer(customerId)
            .then(customer => {
                setCustomer(customer)
                
                setError(false)
            })
            .catch(error => {
                setError(true)
                
            })
            .finally(() => {
                setLoading(false)
            })
        }

       

        if (currentCustomer) {
            fetch(currentCustomer)
        }

    }, [currentCustomer])

    return (
        <CustomerContext.Provider value={customer}>
            <CustomerLoadingContext.Provider value={loading}>
                <CustomerErrorContext.Provider value={error}>
                    {children}
                </CustomerErrorContext.Provider>
            </CustomerLoadingContext.Provider>
        </CustomerContext.Provider>
    )
}

export default CustomerContextProvider;