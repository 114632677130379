import customer from './customer';
import client from './client';
import site from './site';
import authentication from './authentication';
import dashboard from './dashboard';
import qraphql from './graphql';
import dataexport from './export';
import statistics from './statistics';

const controller = { authentication, dashboard, customer, client, site, qraphql, dataexport, statistics }
export { controller as default, authentication, dashboard, customer, client, site, qraphql, dataexport, statistics }