// IMPORT -- react
import { FC } from 'react';
// IMPORT -- project components
import { useCustomer, useCustomerLoading } from '../../../store/CustomerContext';
import LoadingOverlay from '../../../components/LoadingOverlay';

const CustomerTitle: FC = () => {

    const customer = useCustomer();
    const loading = useCustomerLoading();

    return (
        <div className='relative flex sm:hidden flex-col justify-center items-center bg-white rounded-lg border-[1px] border-gray-200 shadow-sm p-2 gap-2'>
            {(loading) && <LoadingOverlay transparent={false} />}
            <span className='text-lg font-semibold text-center'>{customer ? customer.nameFull : ''}</span>
        </div>
    );
};

export default CustomerTitle;