// IMPORT -- react
import { FC, useState, useEffect } from 'react';
// IMPORT -- third party
import { twMerge } from 'tailwind-merge';
import moment from 'moment';
// IMPORT -- icons
import { MdOutlineElectricalServices, MdSolarPower, MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
// IMPORT -- project
import { DeviceType, ClientType, MeterDataType, InverterDataType } from '../../../../utils/types';
import { useSocket } from '../../../../store/SocketContext'; 
import api from '../../../../controllers/api/controller';
import TotalEnergy from './TotalEnergy';
import LatestSignal from './LatestSignal';
import LatestStored from './LatestStored';

interface ListRowClientProps {
    device: DeviceType;
    client: ClientType;
}

const ListRowClient: FC<ListRowClientProps> = ({ device, client }) => {

    const socket = useSocket();

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [status, setStatus] = useState<InverterDataType | MeterDataType | null>(null);
    const [lastStoredAt, setLastStoredAt] = useState<Date | null>(null);
    const [statusColor, setStatusColor] = useState<string>('bg-orange-500');

    useEffect(() => {

        async function statusBlink() {
            setStatusColor('bg-green-400')
            setTimeout(() => {
                setStatusColor('bg-green-500')
            }, 100);
        }

        async function fetch() {
            setLoading(true)
            api.client.getStatus(client.id)
                .then((response) => {
                    if (response) {
                        setStatusColor('bg-green-500')
                        setStatus(response.status);
                        setLastStoredAt(response.lastStoredAt);
                        //console.log(response)
                    } else {
                        setStatusColor('bg-red-500')
                    }
                    setError(false);
                })
                .catch((error) => {
                    setStatusColor('bg-red-500')
                    setError(true)
                })
                .finally(() => setLoading(false))
        }

        function subscribe() {
            socket.emit('clientSubscribe', client.id)
            socket.on(`client-latest-stored-at:${client.id}`, (message: string) => {
                console.log(message)
                if (message) {
                    setLastStoredAt(new Date(message))
                }
            })
            socket.on(client.id, (message: string) => {

                statusBlink()

                if (client.type === 'METER') {
                    const messageObject = JSON.parse(message) as MeterDataType
                    setStatus(messageObject)
                    //console.log(messageObject)
                }

                if (client.type === 'INVERTER') {
                    const messageObject = JSON.parse(message) as InverterDataType
                    setStatus(messageObject)
                    //console.log(messageObject)
                }
            })
        }

        fetch()
        if (socket) subscribe()


        return () => {
            socket.emit('clientUnsubscribe', client.id)
        }

    }, [socket])

    return (
        <div className='flex flex-1 flex-col justify-center items-start pt-2 pb-2 pr-2 pl-6 gap-1 border-b-[1px] border-b-gray-200 hover:bg-blue-100/50 cursor-pointer'>
            <div className='flex flex-1 min-w-full flex-row gap-2'>
                <div className='flex flex-row justify-center items-center'>
                    <div
                        className={twMerge(
                            'w-[15px] h-[15px] rounded-full',
                            statusColor
                        )} />
                </div>
                <div className='flex flex-row justify-center items-center'>
                    {client.type === 'METER' &&
                        <MdOutlineElectricalServices className='text-xl text-blue-500' />
                    }
                    {client.type === 'INVERTER' &&
                        <MdSolarPower className='text-xl text-blue-500' />
                    }
                </div>
                <div className='flex flex-1 flex-row items-center justify-start'>
                    <span className='font-semibold'>
                        {client.name}
                    </span>
                </div>
                {!loading && status && status.clientType === 'METER' &&
                    <>
                        <div className='flex justify-center items-center'>
                            <MdArrowDropDown className='text-2xl text-red-500' />
                        </div>
                        <div className='flex min-w-[70px] justify-start items-center'>
                            <span className='font-semibold'>
                                {powerFormat(status.total_active_power).value}
                            </span>
                        </div>
                        <div className='flex min-w-[25px] justify-end items-center'>
                            <span className='font-semibold'>
                                {powerFormat(status.total_active_power).unit}
                            </span>
                        </div>
                    </>
                }
                {!loading && status && status.clientType === 'INVERTER' &&
                    <>
                        <div className='flex justify-center items-center'>
                            <MdArrowDropUp className='text-2xl text-green-500' />
                        </div>
                        <div className='flex min-w-[70px] justify-start items-center'>
                            <span className='font-semibold'>
                                {powerFormat(status.ac_power).value}
                            </span>
                        </div>
                        <div className='flex min-w-[25px] justify-end items-center'>
                            <span className='font-semibold'>
                                {powerFormat(status.ac_power).unit}
                            </span>
                        </div>
                    </>

                }
            </div>

            {!loading && status && status.clientType === 'METER' &&

                <div className='flex flex-row min-w-full justify-start items-center'>
                    <div className='flex flex-1 flex-row gap-2 justify-start items-center'>
                        <TotalEnergy value={status.total_energy_pos} />
                        <LatestSignal value={status.createdAt} />
                        <LatestStored value={lastStoredAt?.toString() ?? ''} />
                    </div>
                    <div className='flex flex-row justify-end items-center'>
                        <div className='flex flex-row min-w-[70px] justify-center items-center border-r-[1px] border-r-gray-200'>
                            <span className='font-light text-gray-600 text-sm'>
                                {currentFormat(status.current_i1).value}
                            </span>
                        </div>
                        <div className='flex flex-row min-w-[70px] justify-center items-center border-r-[1px] border-r-gray-200'>
                            <span className='font-light text-gray-600 text-sm'>
                                {currentFormat(status.current_i2).value}
                            </span>
                        </div>
                        <div className='flex flex-row min-w-[70px] justify-center items-center'>
                            <span className='font-light text-gray-600 text-sm'>
                                {currentFormat(status.current_i3).value}
                            </span>
                        </div>
                        <div className='flex flex-row min-w-[25px] justify-end items-center'>
                            <span className='font-light text-gray-600 text-sm'>
                                A
                            </span>
                        </div>
                    </div>
                </div>

            }
            {!loading && status && status.clientType === 'INVERTER' &&
                <>
                    <div className='flex flex-row min-w-full justify-start items-center'>
                        <div className='flex flex-1 flex-row gap-2 justify-start items-center'>
                            <TotalEnergy value={status.total_ac_energy} />
                            <LatestSignal value={status.createdAt} />
                            <LatestStored value={lastStoredAt?.toString() ?? ''} />
                        </div>
                        <div className='flex flex-row gap-2 justify-start items-center'>
                            <div className='flex flex-row min-w-[25px] justify-end items-center'>
                                <span className='font-light text-gray-600 text-sm'>
                                    DC:
                                </span>
                            </div>
                            <div className='flex flex-row min-w-[70px] justify-start items-center'>
                                <span className='font-light text-gray-600 text-sm'>
                                    {powerFormat(status.dc_power).value}
                                </span>
                            </div>
                            <div className='flex flex-row min-w-[25px] justify-end items-center'>
                                <span className='font-light text-gray-600 text-sm'>
                                    {powerFormat(status.dc_power).unit}
                                </span>
                            </div>
                        </div>
                    </div>
                </>
            }
            {!loading && !status &&
                <>
                    <div className='flex min-w-full justify-start items-center'>
                        <span className='font-light text-gray-400 text-sm'>
                            Nincs elérhető adat.
                        </span>
                    </div>
                </>
            }
            {loading &&
                <>
                    <div className='flex min-w-full justify-start items-center'>
                        <span className='font-light text-gray-600 text-sm'>
                            Letöltés...
                        </span>
                    </div>
                </>
            }

        </div>
    )

}

function formatNumberThousandSpacing(num: number) {
    if (typeof num === 'number') return num.toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    else return num
}

function powerFormat(value: number): { value: string, unit: string } {

    if (!value) return { value: '0,00', unit: 'W' }
    if (typeof value !== 'number') return { value: '0,00', unit: 'W' }

    switch (true) {
        case (value > 10000): return { value: formatNumberThousandSpacing(Math.floor(value / 10) / 100), unit: 'kW' }
        default: return { value: formatNumberThousandSpacing(Math.floor(value * 100) / 100), unit: 'W' }
    }

}

function currentFormat(value: number): { value: string, unit: string } {

    if (!value) return { value: '0,00', unit: 'A' }
    if (typeof value !== 'number') return { value: '0,00', unit: 'A' }
    return { value: formatNumberThousandSpacing(Math.floor(value * 100) / 100), unit: 'A' }

}

export default ListRowClient;