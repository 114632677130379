import { useState, useEffect, forwardRef } from 'react';

// material-ui
import { Typography, Grid, Divider, Box, Tooltip, Grow, Button, Skeleton, IconButton } from '@mui/material';

// icon
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

// third-party
import ApexCharts from 'apexcharts';
import moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker';
import hu from "date-fns/locale/hu";

// project import
import { formatNumberThousandSpacing, dividing, roundTwoDecimals, dateString } from '../../../utils/utils'
import { useDailyChart, useDailyChartDate, useSetDailyChartDate } from '../../../store/DashboardContext';
import { useStyle } from '../../../store/StyleContext';
import MainCard from '../../../components/MainCard';
import Comparison from '../../../components/Comparison';
import LoadingOverlay from '../../../components/LoadingOverlay';

// ==============================|| DAILY CHART ||============================== //
registerLocale('hu', hu);
const DailyChart = () => {

    const powerUnit = 'kW'
    const energyUnit = 'kWh'
    const styleTheme = useStyle()
    const primaryColor = styleTheme.colors.primary
    const secondColor = styleTheme.colors.secondary
    const prod = primaryColor; //"#015900a0"//theme.palette.primary[700]; //termelés
    const used = secondColor; //"#8f0101"//theme.palette.primary.main; //fogyasztás
    const [mode, setMode] = useState('power')
    const [chart, setChart] = useState()
    const [loading, setLoading] = useState(true)
    const [dailyConsSum, setDailyConsSum] = useState(null);
    const [dailyProdSum, setDailyProdSum] = useState(null);

    // ===== Context =====
    const context = useDailyChart()
    const dailyChartData = context.data
    const dailyChartLoading = context.loading
    //const dailyChartError = dashboardContext.dailyChart.state.error
    const dailyChartDate = useDailyChartDate()
    const setDailyChartDate = useSetDailyChartDate()
    // ===== End context =====

    const [refresh, setRefresh] = useState(false)
    const [fetched, setFetched] = useState(dailyChartData === null ? false : true)

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <Tooltip title="Dátum kiválasztása" arrow>
            <span>
                <Button onClick={onClick} ref={ref} sx={{ color: prod }}>
                    {moment(new Date(value)).format('YYYY.MM.DD.')}
                </Button>
            </span>
        </Tooltip>
    ));

    const increaseDay = () => {
        setRefresh(true)
        const nextDay = moment(new Date(dailyChartDate)).clone().add(1, 'days')
        setDailyChartDate(nextDay.format('YYYY-MM-DD'))
    }

    const decreaseDay = () => {
        setRefresh(true)
        const nextDay = moment(new Date(dailyChartDate)).clone().subtract(1, 'days')
        setDailyChartDate(nextDay.format('YYYY-MM-DD'))
    }

    const isTudayActive = () => {
        return moment(new Date(dailyChartDate)).isSame(new Date(), 'day')
    }

    const chartOptions = {
        chart: {
            height: 450,
            type: 'line',
            toolbar: {
                show: false,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: true,
                    customIcons: []
                },
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 5,
            curve: 'smooth'
        },
        series: [],
        colors: [prod, used],
        xaxis: {
            type: 'datetime',
            min: moment(dailyChartDate, 'YYYY-M-D').startOf('D').toDate().getTime(),
            max: moment(dailyChartDate, 'YYYY-M-D').endOf('D').toDate().getTime(),
            axisBorder: {
                show: true,
            },
            labels: {
                datetimeUTC: false,
                format: 'HH:mm',
            },
        },
        yaxis: {
            show: true,
            labels: {
                formatter: (value) => { return Math.round(value) },
            },
            title: {
                text: powerUnit,
            }
        },
        tooltip: {
            x: {
                format: 'HH:mm',
            },
            y: {
                formatter(val) {
                    if (val === null) return '-'
                    return `${formatNumberThousandSpacing(val)} ${powerUnit}`;
                }
            }
        }
    }

    useEffect(() => {
        const apChart = new ApexCharts(document.querySelector("#chart"), chartOptions)
        setChart(apChart)
        apChart.render()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if (!dailyChartData) setFetched(false)
        if (dailyChartLoading) setRefresh(true)

        if (!dailyChartLoading && dailyChartData && chart) {

            const sumProd = roundTwoDecimals(dividing(dailyChartData.map(i => i.prodEnergy).reduce((partialSum, a) => partialSum + a, 0), 1000))
            const sumCons = roundTwoDecimals(dividing(dailyChartData.map(i => i.consEnergy).reduce((partialSum, a) => partialSum + a, 0), 1000))

            setDailyConsSum(sumCons);
            setDailyProdSum(sumProd);

            const seriesData = dailyChartData.filter(item => moment(item.timestamp).isSameOrBefore(moment()))

            const prodData = mode === 'power' ?
                seriesData.map(item => { return [item.timestamp, roundTwoDecimals(dividing(item.prodPower, 1000))] }) :
                seriesData.map(item => { return [item.timestamp, roundTwoDecimals(dividing(item.prodEnergy, 1000))] })
            const consData = mode === 'power' ?
                seriesData.map(item => { return [item.timestamp, roundTwoDecimals(dividing(item.consPower, 1000))] }) :
                seriesData.map(item => { return [item.timestamp, roundTwoDecimals(dividing(item.consEnergy, 1000))] })

            const series = [
                { name: 'Termelés', data: prodData },
                { name: 'Fogyasztás', data: consData }
            ]

            const chartType = mode === 'power' ? 'line' : 'bar'
            const unitLabel = mode === 'power' ? 'kW' : 'kWh'

            const newOptions = {
                ...chartOptions,
                chart: {
                    ...chartOptions.chart,
                    type: chartType
                },
                yaxis: {
                    ...chartOptions.yaxis,
                    title: {
                        text: unitLabel
                    }
                },
                tooltip: {
                    ...chartOptions.tooltip,
                    y: {
                        formatter(val) {
                            if (val === null) return '-'
                            return `${formatNumberThousandSpacing(val)} ${unitLabel}`;
                        }
                    }
                },
                series: series
            }
            chart.updateOptions(newOptions, true, true)
            setLoading(false)
            setRefresh(false)
            setFetched(true)
        }
        // eslint-disable-next-line
    }, [dailyChartLoading, dailyChartData, mode, chart]);

    return (
        <Grow in={true}>
            <MainCard content={false} loading={refresh}>
                {(refresh || !fetched) && <LoadingOverlay transparent={fetched} />}
                <Box sx={{ position: 'relative' }}>
                    <Grid container alignItems="center" justifyContent="space-between" rowGap={2} columnGap={2} sx={{ p: 1.5 }}>
                        <Grid item>
                            <Typography variant="h5">Napi teljes statisztika - {mode === 'power' ? 'Teljesítmény' : 'Energia'}</Typography>
                        </Grid>
                        <Grid item>
                            <Comparison
                                firstText={dailyProdSum === null || loading ? '-' : `${formatNumberThousandSpacing(dailyProdSum)} ${energyUnit}`}
                                secondText={dailyConsSum === null || loading ? '-' : `${formatNumberThousandSpacing(dailyConsSum)} ${energyUnit}`}
                                firstTooltip="Napelemek termelése"
                                secondTooltip="Rendszer fogyasztása"
                                firstColor={primaryColor}
                                secondColor={secondColor} />
                        </Grid>
                        <Grid item>
                            <div className='dashboard-chart-controls'>
                                <Tooltip title="Ugrás az előző napra" arrow>
                                    <span>
                                        <IconButton size='small' onClick={decreaseDay}>
                                            <ChevronLeftIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <DatePicker
                                    selected={moment(dailyChartDate, 'YYYY-MM-DD').toDate()}
                                    onChange={(date) => setDailyChartDate(moment(date).format('YYYY-MM-DD'))}
                                    customInput={<ExampleCustomInput />}
                                    minDate={new Date('2023-01-01')}
                                    maxDate={new Date()}
                                    locale="hu"
                                />
                                <Tooltip title="Ugrás a következő napra" arrow>
                                    <span>
                                        <IconButton size='small' onClick={increaseDay} disabled={refresh || isTudayActive()}>
                                            <ChevronRightIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Ugrás a mai napra" arrow>
                                    <span>
                                        <IconButton size='small' onClick={() => { setDailyChartDate(dateString(new Date())) }} disabled={refresh || isTudayActive()}>
                                            <EventRepeatIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Teljesítmény" arrow>
                                    <span>
                                        <IconButton size='small' onClick={() => { setMode('power') }} disabled={refresh}>
                                            <ShowChartIcon sx={mode === 'power' ? { color: prod } : null} />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Energia" arrow>
                                    <span>
                                        <IconButton size='small' onClick={() => { setMode('energy') }} disabled={refresh}>
                                            <BarChartIcon sx={mode === 'energy' ? { color: prod } : null} />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Grafikon zoom reset" arrow>
                                    <span>
                                        <IconButton size='small' onClick={() => { chart.resetSeries() }} disabled={refresh}>
                                            <AspectRatioIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </div>
                        </Grid>

                    </Grid>
                    <Divider />
                    <Box sx={{ pt: 1, pr: 2, position: 'relative' }}>
                        <div id='chart' style={{ width: '100%', height: 450, filter: refresh ? 'blur(4px)' : 'none' }}></div>
                        {/*<div style={{ display: loading ? 'block' : 'none', position: 'absolute', top: 'calc(50% - 20px)', left: 'calc(50% - 20px)' }}><CircularProgress /></div>*/}
                    </Box>
                </Box>
                {!fetched ?
                    <Box sx={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', backgroundColor: 'white', p: 0 }} >
                        <Skeleton variant="rectangular" width='100%' height='100%' />
                    </Box> : null}
            </MainCard>
        </Grow>
    )
};

export default DailyChart;