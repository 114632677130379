import React, { useState, useEffect } from 'react';

// material-ui
import { Grid, Button, OutlinedInput, MenuItem, FormControl, Select, Typography, Box, CircularProgress } from '@mui/material';

// icon
import PersonIcon from '@mui/icons-material/Person';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { MdOutlineElectricalServices } from "react-icons/md";

// third-party
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment'
import hu from "date-fns/locale/hu";
import * as xlsx from 'xlsx';
import FileSaver from "file-saver";
import latinize from 'latinize';

// project import
import MainCard from '../../components/MainCard';
//import { useCustomer } from '../../store/Context'
//import apiController from '../../controllers/api'
import utils from '../../utils/utils';
import { useCustomer, useCustomerError, useCustomerLoading } from '../../store/CustomerContext';
import apiController from '../../controllers/api/controller'

registerLocale('hu', hu);
const DataExportDefault = () => {

    const customer = useCustomer()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const [list, setList] = useState([])
    const [selected, setSelected] = useState("")

    const [startDate, setStartDate] = useState(moment().startOf('M').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))

    const divider = 1000
    const unit = 'kWh'

    useEffect(() => {
        const selector = []
        if (customer) {
            selector.push({ type: 'customer', id: customer.id, name: customer.name })

            /*
            customer.sites.map(site => {
                selector.push({ type: 'site', id: site.id, name: site.name })
                site.groups.map(group => {
                    selector.push({ type: 'group', id: group.id, name: group.name })
                    group.clients.map(client => {
                        selector.push({ type: client.type.id, id: client.id, name: client.name })
                        return null
                    })
                    return null
                })
                return null
            })
            */

            setList(selector)
            setLoading(false)
        }
    }, [customer, setList])

    useEffect(() => {

        const getData = async (customerId) => {
            const query = `query DEVICE_TABLE($customerId: String!) 
            {customer(id: $customerId) { id name
                sites { id name 
                    clients { id name type { id name } }
                    groups { id name 
                        clients { id name type { id name } }
                    }
                } 
            } }`
            const variables = { customerId: customerId }
            const params = variables ? { query, variables: variables } : query
            const data = await apiController.qraphql.query(params);
            const customer = data.data.customer;
            
            const selector = []
            
                selector.push({ type: 'customer', id: customer.id, name: customer.name })

                
                customer.sites.map(site => {
                    selector.push({ type: 'site', id: site.id, name: site.name })
                    site.groups.map(group => {
                        selector.push({ type: 'group', id: group.id, name: group.name })
                        group.clients.map(client => {
                            selector.push({ type: client.type.id, id: client.id, name: client.name })
                            return null
                        })
                        return null
                    })
                    return null
                })
                

                setList(selector)
                setLoading(false)
            
        }

        if (customer) getData(customer.id)

    }, [customer, setList, setLoading])

    async function handleSubmit() {

        setError('')
        if (selected === '') return setError('Nincs adatforrás kiválasztva.')
        if (startDate > endDate) return setError('Befejező dátum nem lehet a kezdő dátumnál régebbi.')
        setLoading(true)

        const dataType = (type) => {
            if (type === 'METER' || type === 'INVERTER') return 'client'
            return type
        }

        const data = {
            type: dataType(list[selected].type),
            id: list[selected].id,
            start: startDate,
            end: endDate
        }

        try {
            const response = await apiController.dataexport.dataexportdata(data)



            const fileName = latinize(list[selected].name)
                .replace(/[^a-zA-Z0-9 ]/g, '')
                .toLowerCase()
                .replaceAll('  ', ' ')
                .replaceAll(' ', '_')
                .concat('_', moment(startDate).format('YYYYMMDD').toString())
                .concat('_', moment(endDate).format('YYYYMMDD').toString())
                .concat('.xlsx')



            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";


            const workBook = xlsx.utils.book_new();

            const workSheetHeader = createWorkSheetHeader(list[selected].type, list[selected].name, moment(startDate), moment(endDate), unit)

            const dateTimes = utils.enumerateQhBetweenDates(moment(startDate), moment(endDate))

            const workSheetData = []

            let dataIndex = 0

            for (let i = 0; i < dateTimes.length; i++) {

                const currentData = response[dataIndex]

                if (currentData) {
                    if (moment(currentData.timestamp).isSame(dateTimes[i])) {

                        let currentProdEnergy = 0
                        let currentConsEnergy = 0
                        if (currentData.prodEnergy > 0) currentProdEnergy = currentData.prodEnergy
                        if (currentData.consEnergy > 0) currentConsEnergy = currentData.consEnergy
                        let currentResult = currentProdEnergy - currentConsEnergy
                        currentProdEnergy = utils.roundTwoDecimals(utils.dividing(currentProdEnergy, divider))
                        currentConsEnergy = utils.roundTwoDecimals(utils.dividing(currentConsEnergy, divider))
                        currentResult = utils.roundTwoDecimals(utils.dividing(currentResult, divider))
                        workSheetData.push([`${dateTimes[i].format('YYYY.MM.DD.')}`, `${dateTimes[i].format('HH:mm')}`, currentProdEnergy, currentConsEnergy, currentResult])
                        dataIndex++
                    } else {
                        workSheetData.push([`${dateTimes[i].format('YYYY.MM.DD.')}`, `${dateTimes[i].format('HH:mm')}`, 0, 0, 0])
                    }
                } else {
                    workSheetData.push([`${dateTimes[i].format('YYYY.MM.DD.')}`, `${dateTimes[i].format('HH:mm')}`, '', '', ''])
                }


            }

            const workSheetContent = [...workSheetHeader, ...workSheetData]

            const workSheetName = `SES Export`
            const workSheet = xlsx.utils.aoa_to_sheet(workSheetContent)
            workSheet["!merges"] = createWorkSheetMerge();
            workSheet["!cols"] = createWorkSheetCols();

            xlsx.utils.book_append_sheet(workBook, workSheet, workSheetName)

            const excelBuffer = xlsx.write(workBook, { bookType: "xlsx", type: "array" });

            const blobData = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(blobData, fileName);



            setLoading(false)
        } catch (err) {

            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <Grid container rowSpacing={2} columnSpacing={2} className='w-full h-full p-3 overflow-auto'>
            <Grid item xs={12} md={12} lg={12}>
                <MainCard content={false} title={
                    <Typography variant="h5">Adatexport - Excel - 15 perces eredmények</Typography>
                }>
                    <Box sx={{ padding: '20px' }}>
                        <div style={{ marginBottom: '5px' }}>Adatforrás <span style={{ fontSize: '14px' }}>(telephely / eszközcsoport / eszköz)</span> kiválasztása</div>
                        <div style={{ marginBottom: '20px' }}>
                            <FormControl sx={{ display: 'flex', flex: 1 }}>
                                <Select
                                    id="input-select"
                                    value={selected}
                                    onChange={(e) => { setSelected(e.target.value) }}
                                    input={<OutlinedInput />}
                                    size="small"
                                    disabled={loading}
                                >
                                    {list.length > 0 && list.map((item, index) => (
                                        <MenuItem key={index} value={index} style={{ paddingLeft: otionMargin(item.type) }}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                <OptionIcon type={item.type} />
                                                <div style={{ display: 'flex', gap: 10, paddingTop: 2 }}>
                                                    <div>{item.name}</div>
                                                    <span style={{ fontStyle: 'italic', color: '#6589bf', fontSize: 12 }}>[<OptionTypeName type={item.type} />]</span>
                                                </div>
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ display: 'flex', marginBottom: '20px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                Kezdő dátum:
                                <DatePicker
                                    selected={moment(startDate, 'YYYY-MM-DD').toDate()}
                                    onChange={(date) => { setStartDate(moment(date).format('YYYY-MM-DD')) }}
                                    minDate={new Date('2023-01-01')}
                                    maxDate={new Date()}
                                    locale="hu"
                                    todayButton="Mai nap"
                                    inline
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                <span>Befejező dátum:</span>
                                <DatePicker
                                    selected={moment(endDate, 'YYYY-MM-DD').toDate()}
                                    onChange={(date) => { setEndDate(moment(date).format('YYYY-MM-DD')) }}
                                    minDate={new Date('2023-01-01')}
                                    maxDate={new Date()}
                                    locale="hu"
                                    todayButton="Mai nap"
                                    inline
                                />
                            </div>

                        </div>
                        {loading ?
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: '20px' }}>
                                <CircularProgress style={{ width: 30, height: 30 }} />
                            </Box> : null}
                        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Button
                                size='large'
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit()}
                                startIcon={<CloudDownloadIcon />}
                                disabled={loading}
                            >
                                Letöltés
                            </Button>
                        </div>
                        {error !== '' ?
                            <div style={{ marginTop: '20px', display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: 'red' }}>{error}</span>
                            </div> : null}
                    </Box>
                </MainCard>
            </Grid>
        </Grid>
    )
}

function otionMargin(type) {
    if (type === 'customer') return 10
    if (type === 'site') return 30
    if (type === 'group') return 50
    if (['client', 'METER', 'INVERTER'].includes(type)) return 70
}

const OptionIcon = (props) => {
    const { type } = props
    if (type === 'customer') return <PersonIcon sx={{ color: '#3d5375' }} />
    if (type === 'site') return <HomeWorkOutlinedIcon sx={{ color: '#3d5375' }} />
    if (type === 'group') return <AccountTreeOutlinedIcon sx={{ color: '#3d5375' }} />
    if (type === 'client') return <SolarPowerIcon sx={{ color: '#3d5375' }} />
    if (type === 'INVERTER') return <SolarPowerIcon sx={{ color: '#3d5375' }} />
    if (type === 'METER') return <MdOutlineElectricalServices size={25} color='#3d5375' />
    return null
}

const OptionTypeName = (props) => {
    const { type } = props
    if (type === 'customer') return 'Ügyfél'
    if (type === 'site') return 'Telephely'
    if (type === 'group') return 'Eszközcsoport'
    if (type === 'client') return 'Eszköz'
    if (type === 'METER') return 'Fogyasztásmérő'
    if (type === 'INVERTER') return 'Inverter'
}

function createWorkSheetHeader(dataSourceType, dataSourceName, startDate, endDate, unit) {

    const DataSourceTypes = {
        customer: 'Ügyfél összes eszköz',
        site: 'Telephely',
        group: 'Eszközcsoport',
        client: 'Eszköz'
    }

    const result = [
        ['Adatforrás:', '', DataSourceTypes[dataSourceType]],
        ['Adatforrás név:', '', dataSourceName],
        ['Report típus:', '', '15 perces energia mérések'],
        ['Kezdő dátum:', '', `${startDate.format('YYYY.MM.DD.')}`],
        ['Befejező dátum:', '', `${endDate.format('YYYY.MM.DD.')}`],
        ['Mértékegység:', '', `${unit}`],
        ['Dátum', 'Időpont', 'Termelés', 'Fogyasztás', 'Eredmény']
    ]
    return result
}

function createWorkSheetMerge() {
    const result = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } },
        { s: { r: 0, c: 2 }, e: { r: 0, c: 4 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 1, c: 4 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 1 } },
        { s: { r: 2, c: 2 }, e: { r: 2, c: 4 } },
        { s: { r: 3, c: 0 }, e: { r: 3, c: 1 } },
        { s: { r: 3, c: 2 }, e: { r: 3, c: 4 } },
        { s: { r: 4, c: 0 }, e: { r: 4, c: 1 } },
        { s: { r: 4, c: 2 }, e: { r: 4, c: 4 } },
        { s: { r: 5, c: 0 }, e: { r: 5, c: 1 } },
        { s: { r: 5, c: 2 }, e: { r: 5, c: 4 } }
    ]
    return result
}

function createWorkSheetCols() {
    const result = [{ wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }]
    return result
}

export default DataExportDefault