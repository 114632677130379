import React, { useContext, useState, createContext, useEffect } from 'react'
import socketio from "socket.io-client";

import apiController from '../controllers/api'

const AuthContext = createContext()
const LogoutContext = createContext()
const LoginContext = createContext()
const ConnectedContext = createContext()
const SocketContext = createContext()
const PassportContext = createContext()
const ChangeCustomerContext = createContext()

export function useAuth() {
    return useContext(AuthContext);
}

export function useLogout() {
    return useContext(LogoutContext)
}

export function useLogin() {
    return useContext(LoginContext)
}

export function useConnected() {
    return useContext(ConnectedContext)
}

export function useSocket() {
    return useContext(SocketContext)
}

export function usePassport() {
    return useContext(PassportContext)
}

export function useChangeCustomer() {
    return useContext(ChangeCustomerContext)
}

export let apiAuth = null

export function AuthProvider({ children }) {

    const authInitState = {
        id: null,
        username: null,
        displayName: null,
        email: null,
        access: null,
        sid: null,
        customerIds: null,
        connectLoading: true,
        connectError: '',
        customers: []
    }

    const [passport, setPassport] = useState({ loading: true })
    const [auth, setAuth] = useState(authInitState)
    const [socket, setSocket] = useState(socketio)
    const [connected, setConnected] = useState(false)
    const [currentCustomer, setCurrentCustomer] = useState(null)

    useEffect(() => {
        async function connect() {
            try {
                const res = await apiController.connect()

                if (res.login) return socketLogin(res.data)

                setAuth(current => { return { ...current, connectLoading: false } });

            } catch (err) {
                setAuth(current => {
                    return { ...current, connectLoading: false, connectError: err.message };
                });
            }
        }
        connect()
        // eslint-disable-next-line
    }, [])

    async function login(_email, _password, _remember) {
        return new Promise((resolve, reject) => {
            apiController.login({ email: _email, password: _password, remember: _remember })
                .then(data => {
                    socketLogin(data)
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    function socketLogin(data) {

        const { _ssid, _saccess, _pid } = data

        if (_pid) {
            localStorage.setItem("_pid", _pid)
        }

        const sio = socketio.connect({
            extraHeaders: {
                "_ssid": _ssid,
                "_saccess": _saccess
            }
        })

        sio.on('connect', () => {
            setSocket(sio)
            setAuth({
                id: data.id,
                username: data.username,
                displayName: data.displayName,
                email: data.email,
                access: data._access,
                sid: data._sid,
                customerIds: data.customerIds,
                currentCustomerId: data.customerIds[0] ??= null,
                currentCustomer: currentCustomer,
                customers: data.customers
            })
            apiAuth = { sid: data._sid, access: data._access }
            sessionStorage.setItem("access", data._access);
            setConnected(true)
        })

        sio.on('disconnect', () => {
            setSocket(sio)
            setConnected(false)
            setPassport({ loading: false })
        })
    }

    function logout() {
        localStorage.clear()
        setAuth(authInitState)
        socket.disconnect()
        setAuth(current => { return { ...current, connectLoading: false } });
    }

    const changeCustomer = (customerId) => {
        setAuth((current) => { return { ...current, currentCustomerId: customerId } })
    }

    return (
        <PassportContext.Provider value={passport}>
            <AuthContext.Provider value={auth}>
                <LogoutContext.Provider value={logout}>
                    <LoginContext.Provider value={login}>
                        <ConnectedContext.Provider value={connected}>
                            <SocketContext.Provider value={socket}>
                                <ChangeCustomerContext.Provider value={changeCustomer}>
                                    {children}
                                </ChangeCustomerContext.Provider>
                            </SocketContext.Provider>
                        </ConnectedContext.Provider>
                    </LoginContext.Provider>
                </LogoutContext.Provider>
            </AuthContext.Provider>
        </PassportContext.Provider>
    )
}

export default AuthProvider