// react
import React, { Dispatch, SetStateAction, FC, useState } from "react"

// material-ui
import { Skeleton, Box } from "@mui/material";

// icons
import { IoChevronForward, IoChevronBack } from "react-icons/io5";
import { FaWarehouse } from "react-icons/fa";
import { MdSolarPower, MdPower, MdAccountTree, MdQuestionMark, MdCheck } from "react-icons/md"
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai"

// third party npm
import { twMerge } from "tailwind-merge";

// project
import { SourceItemType } from "../../../../types";


interface DeviceSelectorProps {
    editorLoading: boolean;
    sources: SourceItemType[];
    collector: SourceItemType[];
    setCollector: Dispatch<SetStateAction<SourceItemType[]>>;
    show: boolean;
}

const DeviceSelector: FC<DeviceSelectorProps> = ({ sources, collector, setCollector, show, editorLoading }) => {

    return (

        <div
            className={twMerge(
                "grid-cols-1 md:grid-cols-2 gap-3 w-full flex-1 overflow-hidden px-2 pb-2",
                show ? "grid" : "hidden"
            )}
        >
            <DeviceList editorLoading={editorLoading} setCollector={setCollector} sources={sources} collector={collector} />
            <DeviceList editorLoading={editorLoading} setCollector={setCollector} sources={sources} collector={collector} reverse />
        </div>

    );

};

interface DeviceListProps {
    editorLoading: boolean;
    sources: SourceItemType[];
    collector: SourceItemType[];
    reverse?: boolean;
    setCollector: Dispatch<SetStateAction<SourceItemType[]>>;
}

const DeviceList: FC<DeviceListProps> = ({ sources, collector, reverse, setCollector, editorLoading }) => {

    const devices = reverse ? collector : sources;
    const [searchText, setSearchText] = useState<string>('');
    const filtered = devices.filter(d => d.name.toLowerCase().includes(searchText.toLocaleLowerCase()))

    return (
        <div className="relative bg-white px-2 flex flex-col gap-2 rounded-md border-[1px] border-gray-200 flex-1 overflow-y-auto">
            {editorLoading ?
                <Box sx={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', backgroundColor: 'white', p: 0 }} >
                    <Skeleton variant="rectangular" width='100%' height='100%' />
                </Box>
                : null}
            <div className="flex flex-col w-full p-2">
                <div className="flex felx-row p-2 gap-2 justify-start items-center">
                    {
                        reverse ? (
                            <>
                                <span className="p-1 font-semibold text-base">Kiválasztott adatforrások</span>
                                <div>
                                    <span className="p-1 font-semibold text-gray-600">{collector.length} / 10</span>
                                </div>
                                <div
                                    className="p-1 rounded-full hover:bg-gray-200 border-[1px] border-gray-200 cursor-pointer"
                                    onClick={() => {
                                        setCollector([])
                                    }}
                                >
                                    <AiOutlineClose size={20} />
                                </div>
                                
                            </>
                        ) : (
                            <span className="p-1 font-semibold text-base">Összes adatforrás</span>
                        )
                    }
                </div>
                <div className={twMerge(
                    "flex flex-row gap-2 px-2 justify-center items-center border-[1px]  rounded-md",
                    searchText === "" ? "border-gray-200" : "border-rose-400"
                )}
                >
                    <div className="py-1">
                        <AiOutlineSearch size={22} />
                    </div>
                    <input
                        className="w-full py-2 focus:outline-none outline-none"
                        type="text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Keresés..."
                    />
                    <div
                        className="py-1 group cursor-pointer"
                        onClick={() => {
                            setSearchText("")
                        }}
                    >
                        <AiOutlineClose size={22} className="group-hover:text-gray-950 text-gray-200" />
                    </div>
                </div>
            </div>

            <div className="px-2 flex flex-col flex-1 overflow-auto">

                {filtered.length === 0 &&
                    <div className="flex felx-row w-full justify-center items-center">
                        <span className="text-sm font-semibold text-gray-400">Nincs megjeleníthető elem.</span>
                    </div>
                }

                {filtered.map((item, index) => {
                    const collected = collector.includes(item);

                    return (
                        <div
                            key={index}
                            className={twMerge(
                                "flex flex-row w-full p-2  group hover:bg-gray-100 transition-all cursor-pointer",
                                index === filtered.length - 1 ? "border-b-0" : "border-b-[1px] border-gray-200"
                            )}
                            onClick={() => {
                                if (reverse) {
                                    setCollector(current => current.filter(i => i.id !== item.id));
                                } else {
                                    if (collector.filter(i => i.id === item.id).length === 0) {
                                        if (collector.length < 10) setCollector(current => [...current, item]);
                                    }
                                }
                            }
                            }
                        >

                            {reverse &&
                                <div
                                    className="flex w-[30px] justify-center items-center"

                                >
                                    <IoChevronBack size={22} className="group-hover:scale-125 transition-all" />
                                </div>
                            }
                            <div className="flex w-[40px] justify-center items-center">
                                <DeviceIcon item={item} />
                            </div>
                            <div className="flex flex-1 justify-start items-center px-2">
                                {item.name}
                            </div>
                            {!reverse &&
                                <div
                                    className="flex w-[30px] justify-center items-center"
                                    onClick={() => setCollector(current => [...current, item])}
                                >
                                    {!collected &&
                                        <IoChevronForward size={22} className="group-hover:scale-125 transition-all" />
                                    }
                                    {collected &&
                                        <MdCheck size={22} className="group-hover:scale-125 transition-all text-green-600" />
                                    }
                                </div>
                            }
                        </div>

                    )
                })}
            </div>

        </div>
    )
}

interface DeviceIconProps {
    item: SourceItemType;
}

const DeviceIcon: FC<DeviceIconProps> = ({ item }) => {

    if (item.sourceType === 'site') return <FaWarehouse size={22} />
    if (item.sourceType === 'group') return <MdAccountTree size={22} />
    if (item.sourceType === 'client') {
        switch (item.clientType) {
            case 'INVERTER':
                return <MdSolarPower size={22} />
            case 'METER':
                return <MdPower size={22} />
            default:
                return <MdQuestionMark size={22} />
        }
    }
    return <MdQuestionMark size={22} />

}

export default DeviceSelector;