
// project imports
import api from './api';
import { SiteDataType } from '../../utils/types';

async function getStatus(id: string) {
    return new Promise<SiteDataType>((resolve, reject) => {
            api.get(`site/${id}/status`)
            .then((status) => {
                resolve(status as SiteDataType)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const controller = { getStatus }
export { controller as default, getStatus }