// IMPORT -- react
import { useContext, useState, createContext, useEffect, ReactNode, FC } from 'react';
// IMPORT -- third party
import moment from 'moment';
// IMPORT -- project
import { useCustomer } from './CustomerContext';
import {
    DashboardCleenEnvironmentContextType,
    DashboardCardsContextType,
    DashboardDailyChartContextType,
    DashboardWeekMonthChartContextType,
    DashboardWeekMonthChartDateType,
    DashboardCurrentPowerContextType
} from '../utils/types';
import apiController from '../controllers/api/controller';

const currentDate = moment();
const weekStart = currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD');
const weekEnd = currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD');

const CleenEnvironmentContext = createContext<DashboardCleenEnvironmentContextType>({ loading: false, error: false });
const CardsContext = createContext<DashboardCardsContextType>({ loading: false, error: false });
const DailyChartDateContext = createContext<string>(currentDate.format('YYYY-MM-DD'));
const SetDailyChartDateContext = createContext<(date: string) => void>(() => { });
const DailyChartContext = createContext<DashboardDailyChartContextType>({ loading: false, error: false });
const WeekMonthChartDateContext = createContext<DashboardWeekMonthChartDateType>({ start: weekStart, end: weekEnd });
const SetWeekMonthChartDateContext = createContext<(date: DashboardWeekMonthChartDateType) => void>(() => { });
const WeekMonthChartContext = createContext<DashboardWeekMonthChartContextType>({ loading: false, error: false });
const CurrentPowerContext = createContext<DashboardCurrentPowerContextType>({ loading: false, error: false });
const RefreshContext = createContext<() => void>(() => { });

const useCleenEnvironment = () => useContext(CleenEnvironmentContext);
const useCards = () => useContext(CardsContext);
const useDailyChartDate = () => useContext(DailyChartDateContext);
const useSetDailyChartDate = () => useContext(SetDailyChartDateContext);
const useDailyChart = () => useContext(DailyChartContext);
const useWeekMonthChartDate = () => useContext(WeekMonthChartDateContext);
const useSetWeekMonthChartDate = () => useContext(SetWeekMonthChartDateContext);
const useWeekMonthChart = () => useContext(WeekMonthChartContext);
const useCurrentPower = () => useContext(CurrentPowerContext);
const useRefresh = () => useContext(RefreshContext);

const DashboardContextProvider: FC<{ children: ReactNode }> = ({ children }) => {

    const customer = useCustomer();

    const [cleenEnvironment, setCleenEnvironment] = useState<DashboardCleenEnvironmentContextType>({ loading: false, error: false })
    const [cards, setCards] = useState<DashboardCardsContextType>({ loading: false, error: false })
    const [dailyChartDate, setDailyChartDate] = useState<string>(currentDate.format('YYYY-MM-DD'));
    const [dailyChart, setDailyChart] = useState<DashboardDailyChartContextType>({ loading: false, error: false })
    const [weekMonthChartDate, setWeekMonthChartDate] = useState<DashboardWeekMonthChartDateType>({ start: weekStart, end: weekEnd });
    const [weekMonthChart, setWeekMonthChart] = useState<DashboardWeekMonthChartContextType>({ loading: false, error: false })
    const [currentPower, setCurrentPower] = useState<DashboardCurrentPowerContextType>({ loading: false, error: false })

    async function fetchCleenEnvironment(customerId: string, refresh: boolean) {
        try {
            setCleenEnvironment((prev) => { return { loading: true, error: false, data: refresh ? prev.data : undefined } });
            const data = await apiController.dashboard.getCleenEnvironment(customerId);
            setCleenEnvironment({ loading: false, error: false, data: data });
        } catch (error) {
            setCleenEnvironment({ loading: false, error: true, data: undefined });
        }
    }

    async function fetchCards(customerId: string, refresh: boolean) {
        try {
            setCards((prev) => { return { loading: true, error: false, data: refresh ? prev.data : undefined } });
            const data = await apiController.dashboard.getCards(customerId);
            setCards({ loading: false, error: false, data: data });
        } catch (error) {
            setCards({ loading: false, error: true, data: undefined });
        }
    }

    async function fetchDailyChart(customerId: string, date: string, refresh: boolean) {
        try {
            setDailyChart((prev) => { return { loading: true, error: false, data: refresh ? prev.data : undefined } });
            const data = await apiController.dashboard.getDailyChart(customerId, date)
            setDailyChart({ loading: false, error: false, data: data.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1)) })
        } catch (error) {
            setDailyChart({ loading: false, error: true, data: undefined })
        }
    }

    async function fetchWeekMonthChart(customerId: string, date: DashboardWeekMonthChartDateType, refresh: boolean) {
        try {
            setWeekMonthChart((prev) => { return { loading: true, error: false, data: refresh ? prev.data : undefined } });
            const data = await apiController.dashboard.getWeekMonthChart(customerId, date.start, date.end)
            setWeekMonthChart({ loading: false, error: false, data: data })
        } catch (error) {
            setWeekMonthChart({ loading: false, error: true, data: undefined })
        }
    }

    async function fetchCurrentPower(customerId: string, refresh: boolean) {
        try {
            setCurrentPower((prev) => { return { loading: true, error: false, data: refresh ? prev.data : undefined } });
            const data = await apiController.dashboard.getCurrentPower(customerId)
            setCurrentPower({ loading: false, error: false, data: data })
        } catch (error) {
            setCurrentPower({ loading: false, error: true, data: undefined })
        }
    }

    async function updateDailyChartDate(date: string) {
        setDailyChartDate(date);
        if (customer) fetchDailyChart(customer.id, date, true);
    }

    async function updateWeekMonthChartDate(date: DashboardWeekMonthChartDateType) {
        setWeekMonthChartDate(date);
        if (customer) fetchWeekMonthChart(customer.id, date, true);
    }

    useEffect(() => {
        setDailyChartDate(currentDate.format('YYYY-MM-DD'));
        setWeekMonthChartDate({ start: weekStart, end: weekEnd });
        if (customer) {
            fetchCleenEnvironment(customer.id, false);
            fetchCards(customer.id, false);
            fetchDailyChart(customer.id, currentDate.format('YYYY-MM-DD'), false);
            fetchWeekMonthChart(customer.id, { start: weekStart, end: weekEnd }, false);
            fetchCurrentPower(customer.id, false);
        }
    }, [customer])

    const refresh = (): void => {
        if (customer) {
            fetchCleenEnvironment(customer.id, true);
            fetchCards(customer.id, true);
            fetchDailyChart(customer.id, dailyChartDate, true);
            fetchWeekMonthChart(customer.id, weekMonthChartDate, true);
            fetchCurrentPower(customer.id, true);
        }
    }

    return (
        <CleenEnvironmentContext.Provider value={cleenEnvironment}>
            <CardsContext.Provider value={cards}>
                <DailyChartDateContext.Provider value={dailyChartDate}>
                    <SetDailyChartDateContext.Provider value={updateDailyChartDate}>
                        <DailyChartContext.Provider value={dailyChart}>
                            <WeekMonthChartDateContext.Provider value={weekMonthChartDate}>
                                <SetWeekMonthChartDateContext.Provider value={updateWeekMonthChartDate}>
                                    <WeekMonthChartContext.Provider value={weekMonthChart}>
                                        <CurrentPowerContext.Provider value={currentPower}>
                                            <RefreshContext.Provider value={refresh}>
                                                {children}
                                            </RefreshContext.Provider>
                                        </CurrentPowerContext.Provider>
                                    </WeekMonthChartContext.Provider>
                                </SetWeekMonthChartDateContext.Provider>
                            </WeekMonthChartDateContext.Provider>
                        </DailyChartContext.Provider>
                    </SetDailyChartDateContext.Provider>
                </DailyChartDateContext.Provider>
            </CardsContext.Provider>
        </CleenEnvironmentContext.Provider>
    )
}

export {
    DashboardContextProvider as default,
    useCleenEnvironment,
    useCards,
    useDailyChartDate,
    useSetDailyChartDate,
    useDailyChart,
    useWeekMonthChartDate,
    useSetWeekMonthChartDate,
    useWeekMonthChart,
    useCurrentPower,
    useRefresh
};