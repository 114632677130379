// IMPORT -- react
import { useContext, useState, createContext, ReactNode, FC } from 'react';
// IMPORT -- project
import { MenuType } from '../utils/types';

const menu: Array<MenuType> = [
    { number: 1, type: 'menu', name: 'Áttekintés', module: 'dashboard' },
    { number: 2, type: 'divider', name: '', module: '' },
    { number: 3, type: 'menu', name: 'Eszközök', module: 'clients' },
    { number: 4, type: 'menu', name: 'Adat export', module: 'dataexport' },
    { number: 5, type: 'menu', name: 'Statisztika', module: 'statistics' }
]

const MenuContext = createContext<Array<MenuType>>(menu);
const NavigationContext = createContext<string>('dashboard')
const SetNavigationContext = createContext<React.Dispatch<React.SetStateAction<string>>>(() => { });

export function useMenu() {
    return useContext(MenuContext);
}

export function useNavigation() {
    return useContext(NavigationContext);
}

export function useSetNavigation() {
    return useContext(SetNavigationContext);
}

interface NavigationContextProviderProps {
    children: ReactNode;
}

const NavigationContextProvider: FC<NavigationContextProviderProps> = ({ children }) => {

    const [navigation, setNavigation] = useState<string>('dashboard');

    return (
        <MenuContext.Provider value={menu}>
            <NavigationContext.Provider value={navigation}>
                <SetNavigationContext.Provider value={setNavigation}>
                    {children}
                </SetNavigationContext.Provider>
            </NavigationContext.Provider>
        </MenuContext.Provider>
    )

}

export default NavigationContextProvider;