// IMPORT -- react
import { FC, useState, useEffect } from 'react';
// IMPORT -- third party
import { twMerge } from 'tailwind-merge';
// IMPORT -- icons
import { TbSum } from 'react-icons/tb';
// IMPORT -- project
import { energyFormat } from '../../../../utils/formatters';

interface TotalEnergyProps {
    value: number;
}

const TotalEnergy: FC<TotalEnergyProps> = ({ value }) => {

    const energy = energyFormat(value)
    const [iconColor, setIconColor] = useState<string>('text-violet-500')
    const [iconColorTimer, setIconColorTimer] = useState<NodeJS.Timeout | null>(null)

    useEffect(() => {

        if (iconColorTimer) {
            clearTimeout(iconColorTimer)
            setIconColorTimer(null)
        }

        async function updateIconColor() {
            setIconColor('text-violet-400')
            setIconColorTimer(setTimeout(() => {
                setIconColor('text-violet-500')
            }, 100))
        }

        if (value) {
            updateIconColor()
        }

        return () => {
            if (iconColorTimer) {
                clearTimeout(iconColorTimer)
                setIconColorTimer(null)
            }
        }


    }, [value])

    return (
        <div className='flex flex-row justify-start items-center gap-1'>
            <TbSum className={twMerge('text-xl', iconColor)} />
            <span className='font-light text-gray-600 text-sm min-w-[200px]'>
                {energy.value + ' ' + energy.unit}
            </span>
        </div>
    )

}

export default TotalEnergy;