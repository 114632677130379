// third party
import moment from 'moment';

export function formatNumberThousandSpacing(num: number) {
    if (typeof num === 'number') return num.toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    else return num
}

export function powerFormat(value: number, prefix: boolean = false): { value: string, unit: string } {

    if (!value) return { value: '0,00', unit: 'W' }
    if (typeof value !== 'number') return { value: '0,00', unit: 'W' }

    let sign = ''

    if (prefix && value > 0) sign = '+ ' 
    if (prefix && value < 0) sign = '- ' 

    switch (true) {
        case (Math.abs(value) > 10000): return { value: sign.concat(formatNumberThousandSpacing(Math.floor(Math.abs(value) / 10) / 100)), unit: 'kW' }
        default: return { value: sign.concat(formatNumberThousandSpacing(Math.floor(Math.abs(value) * 100) / 100)), unit: 'W' }
    }

}

export function weightFormat(value: number): { value: string, unit: string } {

    if (!value) return { value: '0,00', unit: 'kg' }
    if (typeof value !== 'number') return { value: '0,00', unit: 'kg' }

    switch (true) {
        case (value > 10_000): return { value: formatNumberThousandSpacing(Math.floor(value / 10) / 100), unit: 'tonna' }
        default: return { value: formatNumberThousandSpacing(Math.floor(value * 100) / 100), unit: 'kg' }
    }

}

export function energyFormat(value: number): { value: string, unit: string } {

    if (!value) return { value: '0,00', unit: 'Wh' }
    if (typeof value !== 'number') return { value: '0,00', unit: 'Wh' }

    switch (true) {
        case (value > 100_000_000): return { value: formatNumberThousandSpacing(Math.floor(value / 10) / 100_000), unit: 'MWh' }
        case (value > 10_000): return { value: formatNumberThousandSpacing(Math.floor(value / 10) / 100), unit: 'kWh' }
        default: return { value: formatNumberThousandSpacing(Math.floor(value * 100) / 100), unit: 'Wh' }
    }

}

export function currentFormat(value: number): { value: string, unit: string } {

    if (!value) return { value: '0,00', unit: 'A' }
    if (typeof value !== 'number') return { value: '0,00', unit: 'A' }
    return { value: formatNumberThousandSpacing(Math.floor(value * 100) / 100), unit: 'A' }

}

export function lastTimestampFormat(value: string): string {

    const timestamp = moment(value)

    const TODAY = moment()
    const YESTERDAY = timestamp.clone().subtract(1, 'days').startOf('day');

    if (timestamp.isSame(TODAY, 'd')) return timestamp.format('HH:mm:ss')
    if (timestamp.isSame(YESTERDAY, 'd')) return 'Tegnap' + timestamp.format('HH:mm:ss')
    return timestamp.format('YYYY.MM.DD. HH:mm:ss')

}