import api from './api';

async function dataexportdata(data: any) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await api.post('export/xlsxData', data)
            resolve(response)
        } catch (err) {
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

const controller = { dataexportdata }
export { controller as default, dataexportdata }