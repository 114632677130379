// react
import React, { FC, useEffect, useState } from "react";

// third party npm
import moment from "moment";

// project
//import { useCustomer } from "../../store/Context";
import { SourceItemType, StatDividerType, StatPeriodType } from "../../types";
//import apiController from "../../controllers/api";
import apiController from '../../controllers/api/controller';
import Stepper from "./components/editor/Stepper";
import DeviceSelector from "./components/editor/DeviceSelector";
import PeriodSelector from "./components/editor/PeriodSelector";
import OptionsSelector from "./components/editor/OptionsSelector";
import Result from "./components/editor/Result";
import { useCustomer } from "../../store/CustomerContext";


const Editor: FC = () => {

    const customer = useCustomer();

    const [editorLoading, setEditorLoading] = useState<boolean>(false)

    const [currentStep, setCurrentStep] = useState<number>(1);
    const [sources, setSources] = useState<SourceItemType[]>([]);
    const [collector, setCollector] = useState<SourceItemType[]>([]);

    const [startDate, setStartDate] = useState<string>(moment().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DD'))

    const [periodType, setPeriodType] = useState<StatPeriodType>('day')

    const [dataSumType, setDataSumType] = useState<boolean>(false)

    const [dataDivider, setDataDivider] = useState<StatDividerType>(1000)


    useEffect(() => {
        async function fetch(customerId: string) {
            setEditorLoading(true)
            const response = await apiController.statistics.getProperties(customerId)
            setSources(response as SourceItemType[]);
            setCollector([]);
            setCurrentStep(1);
            setEditorLoading(false)
        }
        if (customer) fetch(customer.id);
    }, [customer]);

   

    const collected = collector.length;

    return (
        <div className="flex flex-col flex-1 overflow-hidden">
            <Stepper
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            collected={collected}
            />
            <DeviceSelector
                editorLoading={editorLoading}
                show={currentStep === 1 ? true : false}
                sources={sources}
                collector={collector}
                setCollector={setCollector}
            />
            <PeriodSelector
                show={currentStep === 2 ? true : false}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                periodType={periodType}
                setPeriodType={setPeriodType}
            />
            <OptionsSelector
                show={currentStep === 3 ? true : false}
                dataSumType={dataSumType}
                setDataSumType={setDataSumType}
                dataDivider={dataDivider}
                setDataDivider={setDataDivider}
            />
            <Result
                show={currentStep === 4 ? true : false}
                collector={collector}
                startDate={startDate}
                endDate={endDate}
                dataResolution={periodType}
                dataSumType={dataSumType}
                dataDivider={dataDivider}
            />
        </div>
    );
};

export default Editor;