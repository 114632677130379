
// project imports
import api from './api';
import { CustomerType } from '../../utils/types';

async function getCustomer(customerId: string) {
    return new Promise<CustomerType>((resolve, reject) => {
            api.get(`customer/${customerId}`)
            .then((customer) => {
                resolve(customer as CustomerType)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const controller = { getCustomer }
export { controller as default, getCustomer }