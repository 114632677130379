// IMPORT -- project
import api from './api';
import { DashboardCleenEnvironmentDataType, DashboardCardsDataType, ChartDataType, ChartDailyDataType, DashboardCurrentPowerDataType } from '../../utils/types';

async function getCleenEnvironment(customerId: string): Promise<DashboardCleenEnvironmentDataType> {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await api.get(`dashboard/cleenEnvironment/${customerId}`);
            resolve(data as DashboardCleenEnvironmentDataType)
        } catch(error) {
            return reject(error)
        }
    })
}

async function getCards(customerId: string): Promise<DashboardCardsDataType> {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await api.get(`dashboard/cards/${customerId}`);
            resolve(data as DashboardCardsDataType)
        } catch(error) {
            return reject(error)
        }
    })
}

async function getDailyChart(customerId: string, date: string): Promise<[ChartDataType]> {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await api.get(`dashboard/dailyChart/${customerId}/${date}`);
            resolve(data as [ChartDataType])
        } catch(error) {
            return reject(error)
        }
    })
}

async function getWeekMonthChart(customerId: string, startDate: string, endDate: string): Promise<[ChartDailyDataType]> {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await api.get(`dashboard/weekMonthChart/${customerId}/${startDate}/${endDate}`);
            resolve(data as [ChartDailyDataType])
        } catch(error) {
            return reject(error)
        }
    })
}

async function getCurrentPower(customerId: string): Promise<DashboardCurrentPowerDataType> {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await api.get(`dashboard/currentPower/${customerId}`);
            resolve(data as DashboardCurrentPowerDataType)
        } catch(error) {
            return reject(error)
        }
    })
}

const controller = { getCleenEnvironment, getCards, getDailyChart, getWeekMonthChart, getCurrentPower }
export {
    controller as default,
    getCleenEnvironment,
    getCards,
    getDailyChart,
    getWeekMonthChart,
    getCurrentPower
}