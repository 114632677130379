// third party
import axios from '../axios';

async function get(path: string) {
    const access = sessionStorage.getItem("access");
    return new Promise((resolve, reject) => {
        axios.get(`/api/${path}`, { headers: { 'Authorization': 'Bearer ' + access } })
            .then(resp => {
                if (resp.status !== 200) reject(new Error('Api error'))
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

async function post(path: string, data: any) {
    const access = sessionStorage.getItem("access");
    return new Promise((resolve, reject) => {
        axios.post(`/api/${path}`, data, { headers: { 'Authorization': 'Bearer ' + access } })
            .then(resp => {
                if (resp.status !== 200) reject(new Error('Api error'))
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

const controller = { get, post }
export { controller as default, get, post }