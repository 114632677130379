import React, { FC, useEffect, useState } from "react";
import { useSocket } from "../../store/AuthContext";
import { Socket } from "socket.io-client";
import apiController from "../../controllers/api";
import moment from "moment";

import Chart from "react-apexcharts";

interface DiagnosticProps {
    sourceId: string;
    onSelectBack: () => void;
}

type diagMeterType = {
    current_i1: number;
    current_i2: number;
    current_i3: number;
    current_in: number;
    total_active_power: number;
}

const Diagnostic: FC<DiagnosticProps> = ({ sourceId, onSelectBack }) => {

    const socket: Socket = useSocket();
    const [diagData, setDiagData] = useState<diagMeterType | null>(null)
    const [datasetCurrentI1, setDatasetCurrentI1] = useState<number[]>([])
    const [datasetCurrentI2, setDatasetCurrentI2] = useState<number[]>([])
    const [datasetCurrentI3, setDatasetCurrentI3] = useState<number[]>([])
    const [datasetTimes, setDatasetTimes] = useState<moment.Moment[]>([])

    const [dataSet, setDataSet] = useState<diagMeterType[]>([]);

    useEffect(() => {
        socket.emit('sourceSubscribe', sourceId)
        socket.on(sourceId, (payload) => {
            console.log(payload)
            const jsonPayload: diagMeterType = JSON.parse(payload);



            setDiagData(jsonPayload)
        })

        async function fetch() {
            const response = await apiController.diagnostic.getClientState(sourceId);
            console.log(response)
            setDiagData(response)
        }

        return () => {
            socket.emit('sourceUnsubscribe', sourceId)
            socket.off(sourceId)
        }

    }, [])

    useEffect(() => {

        if (diagData) {

            setDatasetCurrentI1(current => [...(datasetCurrentI1.length < 10 ? current : current.slice(1)), Math.round(diagData.current_i1 * 100) / 100])
            setDatasetCurrentI2(current => [...(datasetCurrentI2.length < 10 ? current : current.slice(1)), Math.round(diagData.current_i2 * 100) / 100])
            setDatasetCurrentI3(current => [...(datasetCurrentI3.length < 10 ? current : current.slice(1)), Math.round(diagData.current_i3 * 100) / 100])



            setDataSet(current => [...(current.length < 10 ? current : current.slice(1)), diagData])


            //console.log(datasetTimes)
        }

    }, [diagData])

    return (
        <div className="flex flex-col">
            <div>
                <div onClick={onSelectBack}>
                    Vissza
                </div>
            </div>
            {diagData &&
                <div className="flex flex-col gap-4">
                    <div>
                        Diag - {sourceId}

                    </div>
                    <div className="grid grid-cols-3 w-full gap-2 px-2">
                        <div className="flex flex-col gap-2 bg-white p-2">
                            <div className="flex flex-row gap-2">
                                <div className="flex flex-1 justify-center items-center flex-row border-2 border-gray-200 rounded-md px-2 py-1">
                                    <span>{Math.round(diagData.total_active_power / 10) / 100} W</span>
                                </div>

                            </div>

                            <Chart
                                options={{
                                    chart: {
                                        id: "basic-bar-a",
                                        toolbar: {
                                            show: false
                                        },
                                        animations: {
                                            enabled: false,
                                        },
                                    },
                                    stroke: {
                                        curve: "smooth"
                                    },
                                    dataLabels: {
                                        enabled: false
                                    },
                                    tooltip: {
                                        enabled: false
                                    },
                                    xaxis: {
                                        labels: {
                                            show: false,
                                        },
                                        axisTicks: {
                                            show: false
                                        }
                                    },
                                    yaxis: {
                                        labels: {
                                            show: true,
                                            formatter: function (val, index) {
                                                return Math.round(val).toString();
                                            }
                                        }
                                    }
                                }}
                                series={[
                                    {
                                        name: "I1",
                                        data: dataSet.map(d => (Math.round(d.total_active_power / 10) / 100))
                                    },

                                ]}
                                type="line"

                                width={"100%"}
                                height={200}
                            />
                        </div>
                        <div className="flex flex-col gap-2 bg-white p-2">

                            <div className="flex flex-row gap-2">
                                <div className="flex flex-1 justify-center items-center flex-row border-2 border-gray-200 rounded-md px-2 py-1">
                                    <span>{Math.round(diagData.current_i1 * 100) / 100} A</span>
                                </div>
                                <div className="flex flex-1 justify-center items-center flex-row border-2 border-gray-200 rounded-md px-2 py-1">
                                    <span>{Math.round(diagData.current_i2 * 100) / 100} A</span>
                                </div>
                                <div className="flex flex-1 justify-center items-center flex-row border-2 border-gray-200 rounded-md px-2 py-1">
                                    <span>{Math.round(diagData.current_i3 * 100) / 100} A</span>
                                </div>

                            </div>

                            <Chart
                                options={{
                                    chart: {
                                        id: "basic-bar",
                                        toolbar: {
                                            show: false
                                        },
                                        animations: {
                                            enabled: false,
                                        },
                                        
                                    },
                                    legend: {
                                        show: false
                                    },
                                    tooltip: {
                                        enabled: false
                                    },
                                    stroke: {
                                        curve: "smooth"
                                    },
                                    xaxis: {
                                        labels: {
                                            show: false,
                                        },
                                        axisTicks: {
                                            show: false
                                        },
                                        tooltip: {
                                            enabled: false
                                        }
                                        
                                    },
                                    yaxis: {
                                        labels: {
                                            show: true,
                                            formatter: function (val, index) {
                                                return Math.round(val).toString();
                                            }
                                        }
                                    }
                                }}
                                series={[
                                    {
                                        name: "I1",
                                        data: dataSet.map(d => (Math.round(d.current_i1 * 100) / 100))
                                    },
                                    {
                                        name: "I2",
                                        data: dataSet.map(d => (Math.round(d.current_i2 * 100) / 100))
                                    },
                                    {
                                        name: "I3",
                                        data: dataSet.map(d => (Math.round(d.current_i3 * 100) / 100))
                                    }
                                ]}
                                type="line"

                                width={"100%"}
                                height={200}
                            />

                        </div>
                    </div>
                </div>
            }
        </div >
    )
}



export default Diagnostic;