// react
import React from "react";

// material-ui
import { ListItem, ListItemText, ListItemIcon, ListItemButton, Divider } from '@mui/material';

// icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import LanIcon from '@mui/icons-material/Lan';
import { BsFillDiagram3Fill } from "react-icons/bs"

// project import
import { Drawer, DrawerHeader } from "./Styled";
//import { useSetNavigation } from "../store/Context";
import { useMenu, useSetNavigation } from "../store/NavigationContext";

const Sidebar = (props) => {

   const setNavigation = useSetNavigation()
   const menu = useMenu()
   const open = props.drawerOpen

   const handleClickMenu = (module) => {
      setNavigation(module)
   }

   const ItemIcon = (props) => {
      switch (props.module) {
         case "dashboard":
            return <DashboardIcon />
         case "statistics":
            return <BarChartIcon />
         case "dataexport":
            return <FileOpenIcon />
         case "diagnostics":
            return <TroubleshootIcon />
         case "clients":
            return <LanIcon />
         default:
            return null
      }
   }

   return (
      <Drawer variant="permanent" open={open} sx={{ display: { xs: 'none', md: 'flex' } }}>
         <DrawerHeader />
         {menu.map(item => {
            if (item.type === 'divider') return <Divider key={item.number} />
            if (item.type === 'menu') return (
               <ListItem key={item.number} disablePadding sx={{ display: 'block' }} onClick={() => handleClickMenu(item.module)}>
                  <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                     <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', alignItems: 'center' }}>
                        <ItemIcon module={item.module} />
                     </ListItemIcon>
                     <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
               </ListItem>
            )
            return null
         })}
      </Drawer>
   )

}

export default Sidebar