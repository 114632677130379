import { FC, useState } from 'react';

import moment, { Moment } from 'moment';

import { Tooltip, IconButton } from '@mui/material';

import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { DashboardWeekMonthChartDateType } from '../../../utils/types';

interface WeekStepperProps {
    setWeekMonthDate: (updatedDate: DashboardWeekMonthChartDateType) => void;
    weekMonthLoading: boolean;
}

const WeekStepper: FC<WeekStepperProps> = ({ setWeekMonthDate, weekMonthLoading }) => {

    const current = moment();
    const [selected, setSelected] = useState<Moment>(current.clone());

    const handleChangeWeek = (updatedWeek: Moment) => {
        const startDate = updatedWeek.startOf('isoWeek').format('YYYY-MM-DD');
        const endDate = updatedWeek.endOf('isoWeek').format('YYYY-MM-DD');
        setSelected(updatedWeek);
        setWeekMonthDate({ start: startDate, end: endDate });
    }

    const handleDecrementWeek = () => handleChangeWeek(selected.clone().subtract(1, 'weeks'));
    const handleIncrementWeek = () => handleChangeWeek(selected.clone().add(1, 'weeks'));
    const handleResetWeek = () => handleChangeWeek(current.clone());

    const selectedYear = selected.clone().format('YYYY')
    const selectedWeekNum = selected.clone().format('W')

    return (
        <>
            <Tooltip title="Előző hét" arrow>
                <span>
                    <IconButton size='small' onClick={() => handleDecrementWeek()} disabled={weekMonthLoading}>
                        <ChevronLeftIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Kiválasztott hét" arrow>
                <span style={{ fontSize: '0.9rem', color: '#1976d2' }}>{selectedYear}. {selectedWeekNum}. hét</span>
            </Tooltip>
            <Tooltip title="Következő hét" arrow>
                <span>
                    <IconButton size='small' onClick={() => handleIncrementWeek()} disabled={(selected.format('YYYY-W') === current.format('YYYY-W')) || weekMonthLoading}>
                        <ChevronRightIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Ugrás az aktuális hétre" arrow>
                <span>
                    <IconButton size="small" color={"primary"} onClick={() => handleResetWeek()} disabled={(selected.format('YYYY-W') === current.format('YYYY-W')) || weekMonthLoading}>
                        <EventRepeatIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    )
}

export default WeekStepper;