// react
import { FC, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

// material-ui
import { Box, Button, FormHelperText, Grid, Grow, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Paper, CircularProgress, Alert, Switch, Fade, Typography } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WarningIcon from '@mui/icons-material/Warning';
import { MdAlternateEmail } from "react-icons/md";
import { MdOutlineVisibility, MdOutlineVisibilityOff, MdVisibility, MdVisibilityOff } from "react-icons/md";

// assets
import logo from '../assets/images/logo-with-letters.jpg';
import background from '../assets/images/login-background.jpg';

// project import
//import { useAuth } from "../store/AuthContext";
import { useLogin, useConnectLoading } from "../store/UserContext";

interface LoginPageProps {
    variant?: 'default' | 'admin';
}

const LoginPage: FC<LoginPageProps> = ({ variant = 'default' }) => {

    const navigate = useNavigate();
    //const auth = useAuth()
    //const { connectLoading, connectError } = auth
    const connectLoading = useConnectLoading()
    const connectError = false

    const formTitle = (): string => {
        switch (variant) {
            case 'default': return 'Ügyfélportál belépés';
            case 'admin': return 'Adminportál belépés';
        }
    };

    const pageTitle = (): string => {
        switch (variant) {
            case 'default': return 'SES Ügyfélportál';
            case 'admin': return 'SES Adminportál';
        }
    };

    useEffect(() => {
        document.title = pageTitle()
    }, [])

    const LoginForm = () => {

        const handleLogin = useLogin()
        const [loading, setLoading] = useState(false)
        const [showPassword, setShowPassword] = useState(false);

        const handleClickShowPassword = () => {
            setShowPassword(!showPassword);
        };

        const handleMouseDownPassword = (event: { preventDefault: () => void; }) => {
            event.preventDefault();
        };

        interface TextInputProps {
            title?: string;
            model?: 'normal' | 'email' | 'password';
        }

        const TextInput: FC<TextInputProps> = ({ title, model = 'normal' }) => {

            const inputType = (): string => {
                switch (model) {
                    case 'email': return 'text';
                    case 'normal': return 'text';
                    case 'password': return 'password';
                }
            }

            return (
                <div className="flex flex-col gap-1 w-full justify-center items-start grow">
                    {title &&
                        <span className="text-gray-500 font-light">{title}</span>
                    }
                    <div className="flex flex-row justify-between items-center w-full rounded-md border-gray-300 border-[1px] overflow-hidden">

                        <input type="text" className="p-2 w-full outline-none" />
                        {model === 'password' &&
                            <div className="flex justify-center items-center pl-2 pr-2 cursor-pointer">
                                <MdVisibilityOff className="text-[25px] text-gray-500" />
                            </div>
                        }
                    </div>
                </div>
            )
        }

        /*
        return (
            <div className="flex flex-col justify-center items-center p-4 gap-4 bg-white z-10 rounded-md shadow-md shadow-black/30">
                <div className="flex flex-col justify-center items-center">
                    <img src={logo} alt="logo" className="w-[300px]" />
                    <span>{formTitle()}</span>
                </div>
                <div className="felx felx-col w-full justify-center items-center">

                    <TextInput title="E-mail cím" />

                    <TextInput title="Jelszó" model="password" />

                </div>
            </div>
        )
        */

        return (

            <Grow in={true}
                style={{ transformOrigin: '0 0 0' }} timeout={1000}>
                <Paper elevation={3} sx={{ width: 340, p: 2, display: 'flex', flexDirection: 'column', gap: 2, zIndex: 1 }}>
                    <>
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                                remember: false,
                                submit: null,
                                loading: loading,
                                success: false
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email('Email címnek érvényesnek kell lennie').max(255).required('Email címet kötelező megadni'),
                                password: Yup.string().max(255).required('Jelszó kötelező')
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                setLoading(true)
                                try {
                                    await handleLogin(values.email, values.password, values.remember);
                                    setStatus({ success: true });
                                    setSubmitting(true);
                                } catch (error: unknown) {
                                    if (error instanceof Error) {
                                        setStatus({ success: false });
                                        setErrors({ submit: error.message });
                                        setSubmitting(false);
                                        setLoading(false)
                                    }
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <img src={logo} alt="logo" style={{ width: 300 }} />
                                            <Typography variant="h5" style={{ textAlign: "center" }}>{formTitle()}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="email-login">Email cím</InputLabel>
                                                <OutlinedInput
                                                    id="email-login"
                                                    type="email"
                                                    value={values.email}
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Email cím"
                                                    fullWidth
                                                    error={Boolean(touched.email && errors.email)}
                                                />
                                                {touched.email && errors.email && (
                                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                                        {errors.email}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="password-login">Jelszó</InputLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    error={Boolean(touched.password && errors.password)}
                                                    id="-password-login"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={values.password}
                                                    name="password"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                size="large"
                                                            >
                                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    placeholder="********"
                                                />
                                                {touched.password && errors.password && (
                                                    <FormHelperText error id="standard-weight-helper-text-password-login">
                                                        {errors.password}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1} direction='row' alignItems='center'>
                                                <Switch
                                                    id="remember-login"
                                                    name="remember"
                                                    value={false}
                                                    checked={values.remember === true}
                                                    onChange={(event, checked) => {
                                                        setFieldValue("remember", checked ? true : false);
                                                    }}
                                                />
                                                <InputLabel htmlFor="password-login">Emlékezz rám</InputLabel>
                                            </Stack>
                                        </Grid>
                                        {errors.submit ?
                                            <Grid item xs={12}>
                                                <Fade in={true}>
                                                    <Alert severity="error">{errors.submit}</Alert>
                                                </Fade>
                                            </Grid> : null}
                                        {isSubmitting || loading ?
                                            <Grid item xs={12}>
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <CircularProgress style={{ width: 30, height: 30 }} />
                                                </Box>
                                            </Grid>
                                            : null}

                                        <Grid item xs={12}>
                                            <Box style={{ display: "flex", flexDirection: 'column', gap: 10 }}>
                                                <Button
                                                    disableElevation
                                                    disabled={isSubmitting || loading}
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Bejelentkezés
                                                </Button>
                                                <Button
                                                    disabled={isSubmitting || loading}
                                                    fullWidth
                                                    size='small'
                                                    type='button'
                                                    variant='text'
                                                    color='primary'
                                                    onClick={() => { navigate('/reminder') }}
                                                >
                                                    Elfelejtettem a jelszavam
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </>
                </Paper>
            </Grow>
        )
    }

    const ErrorView = () => {
        return (
            <Grow in={true}
                style={{ transformOrigin: '0 0 0' }} timeout={1000}>
                <Paper elevation={3} sx={{ width: 340, p: 2, display: 'flex', flexDirection: 'column', gap: 2, zIndex: 1 }}>
                    <div style={{ display: 'flex', flex: 1, gap: 20, alignItems: 'center', justifyContent: 'center', color: '#233044' }}>
                        <WarningIcon />{connectError && connectError !== '' ? connectError : 'Kapcsolódási hiba történt.'}
                    </div>
                </Paper>
            </Grow>
        )
    }

    const LoadingView = () => {
        return (
            <Grow in={true}
                style={{ transformOrigin: '0 0 0' }} timeout={1000}>
                <Paper elevation={3} sx={{ width: 340, p: 2, display: 'flex', flexDirection: 'column', gap: 2, zIndex: 1 }}>
                    <div style={{ display: 'flex', flex: 1, gap: 20, alignItems: 'center', justifyContent: 'center', color: '#233044' }}>
                        <CircularProgress style={{ width: 30, height: 30 }} />
                    </div>
                </Paper>
            </Grow>
        )
    }

    const Content = () => {
        if (connectLoading) return <LoadingView />
        if (connectError) return <ErrorView />
        return <LoginForm />
    }

    return (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }} >
            <img src={background} alt="logo-background" style={{ opacity: '37%', zIndex: 0, position: 'fixed', bottom: 0 }} />
            <Content />
        </div>
    )
}

export default LoginPage