// react
import React, { FC, useEffect } from "react";

// third party npm
import { twMerge } from "tailwind-merge";
import moment from "moment";
import DatePicker from "react-datepicker";

// project components
import { StatPeriodType } from "../../../../types";

interface PeriodSelectorProps {
    show: boolean;
    startDate: string;
    endDate: string;
    setStartDate: (date: string) => void;
    setEndDate: (date: string) => void;
    periodType: StatPeriodType;
    setPeriodType: (type: StatPeriodType) => void;
}

const PeriodSelector: React.FC<PeriodSelectorProps> = ({ show, startDate, endDate, setStartDate, setEndDate, periodType, setPeriodType }) => {

    useEffect(() => {
        if (periodType === "day") {
            setStartDate(moment().format('YYYY-MM-DD'))
            setEndDate(moment().format('YYYY-MM-DD'))
        }
        if (periodType === "week") {
            setStartDate(moment().startOf('W').format('YYYY-MM-DD'))
            setEndDate(moment().endOf('W').format('YYYY-MM-DD'))
        }
        if (periodType === "pastday") {
            const pastDate = moment().subtract(1, "days").format("YYYY-MM-DD")
            setStartDate(pastDate)
            setEndDate(pastDate)
        }
        if (periodType === "currentMonth") {
            setStartDate(moment().startOf('month').format("YYYY-MM-DD"))
            setEndDate(moment().endOf('month').format("YYYY-MM-DD"))
        }
        if (periodType === "month") {
            setStartDate(moment().startOf('month').format("YYYY-MM-DD"))
            setEndDate(moment().endOf('month').format("YYYY-MM-DD"))
        }
        if (periodType === "pastMonth") {
            const pastDate = moment().subtract(1, "months")
            setStartDate(moment(pastDate).startOf('month').format("YYYY-MM-DD"))
            setEndDate(moment(pastDate).endOf('month').format("YYYY-MM-DD"))
        }
        if (periodType === "currentYear") {
            setStartDate(moment().startOf('year').format("YYYY-MM-DD"))
            setEndDate(moment().endOf('year').format("YYYY-MM-DD"))
        }
        if (periodType === "year") {
            setStartDate(moment().startOf('year').format("YYYY-MM-DD"))
            setEndDate(moment().endOf('year').format("YYYY-MM-DD"))
        }
        if (periodType === "pastYear") {
            const pastDate = moment().subtract(1, "years")
            setStartDate(moment(pastDate).startOf('year').format("YYYY-MM-DD"))
            setEndDate(moment(pastDate).endOf('year').format("YYYY-MM-DD"))
        }
        // eslint-disable-next-line
    }, [periodType])
    return (

        <div className={twMerge(
            "flex flex-1 flex-col md:flex-row gap-2 overflow-auto px-2 pb-2",
            show ? "flex" : "hidden"
        )}>
            <div className="flex flex-col flex-1 bg-white border-[1px] border-gray-200 rounded-md p-2">
                <div className="flex flex-col w-full h-min">

                    <div className="flex flex-row w-full gap-4 p-2">

                        <PeriodTypeButton
                            title="Egy nap"
                            subTitle="15 perces adatok"
                            type="day"
                            selected={periodType === "day" ? true : false}
                            onSelect={setPeriodType}
                        />

                        <PeriodTypeButton
                            title="Egy hét"
                            subTitle="napi összesített"
                            type="week"
                            selected={periodType === "week" ? true : false}
                            onSelect={setPeriodType}
                        />

                        <PeriodTypeButton
                            title="Előző nap"
                            subTitle="15 perces adatok"
                            type="pastday"
                            selected={periodType === "pastday" ? true : false}
                            onSelect={setPeriodType}
                        />



                    </div>


                </div>

                <div className="flex flex-col w-full h-min">

                    <div className="flex flex-row w-full gap-4 p-2">

                        <PeriodTypeButton
                            title="Aktuális hónap"
                            subTitle="napi összesített"
                            type="currentMonth"
                            selected={periodType === "currentMonth" ? true : false}
                            onSelect={setPeriodType}

                        />

                        <PeriodTypeButton
                            title="Kiválasztott hónap"
                            subTitle="napi összesített"
                            type="month"
                            selected={periodType === "month" ? true : false}
                            onSelect={setPeriodType}
                        />

                        <PeriodTypeButton
                            title="Előző hónap"
                            subTitle="napi összesített"
                            type="pastMonth"
                            selected={periodType === "pastMonth" ? true : false}
                            onSelect={setPeriodType}
                        />



                    </div>


                </div>

                <div className="flex flex-col w-full h-min">

                    <div className="flex flex-row w-full gap-4 p-2">

                        <PeriodTypeButton
                            title="Aktuális év"
                            subTitle="havi összesített"
                            type="currentYear"
                            selected={periodType === "currentYear" ? true : false}
                            onSelect={setPeriodType}
                        />

                        <PeriodTypeButton
                            title="Kiválasztott év"
                            subTitle="havi összesített"
                            type="year"
                            selected={periodType === "year" ? true : false}
                            onSelect={setPeriodType}
                        />

                        <PeriodTypeButton
                            title="Előző év"
                            subTitle="havi összesített"
                            type="pastYear"
                            selected={periodType === "pastYear" ? true : false}
                            onSelect={setPeriodType}
                        />



                    </div>


                </div>
            </div>
            <div className="flex bg-white flex-1 justify-center items-center border-[1px] border-gray-200 rounded-md p-4">
                {periodType === "day" &&
                    <div className="flex flex-col justify-center items-center gap-4">

                        <span className="text-base font-semibold">Dátum kiválasztása:</span>
                        <DatePicker
                            selected={moment(startDate, 'YYYY-MM-DD').toDate()}
                            onChange={(date) => {
                                setStartDate(moment(date).format('YYYY-MM-DD'))
                                setEndDate(moment(date).format('YYYY-MM-DD'))
                            }}
                            minDate={new Date('2023-01-01')}
                            maxDate={new Date()}
                            locale="hu"
                            todayButton="Mai nap"
                            inline
                            disabled={true}
                        />

                    </div>
                }
                {periodType === "week" &&
                    <div className="flex flex-col justify-center items-center gap-4">

                        <span className="text-base font-semibold">Hét kiválasztása:</span>
                        <DatePicker
                            selected={moment(startDate, 'YYYY-MM-DD').startOf('W').toDate()}
                            onChange={(date) => {
                                console.log(date)
                                setStartDate(moment(date).startOf('W').format('YYYY-MM-DD'))
                                setEndDate(moment(date).endOf('W').format('YYYY-MM-DD'))
                                console.log(moment(date).startOf('W').format('YYYY-MM-DD'))
                                console.log(moment(date).endOf('W').format('YYYY-MM-DD'))
                            }}
                            minDate={new Date('2023-01-01')}
                            maxDate={new Date()}
                            locale="hu"
                            todayButton="Mai nap"
                            inline
                            disabled={true}

                            showWeekPicker

                        />

                    </div>
                }
                {periodType === "pastday" &&
                    <div className="flex flex-col justify-center items-center gap-4">
                        <span className="text-base font-semibold">Kiválasztott dátum:</span>
                        <span className="text-base font-semibold">{moment(startDate).format("YYYY. MM. DD.")}</span>
                    </div>
                }
                {periodType === "currentMonth" &&
                    <div className="flex flex-col justify-center items-center gap-4">
                        <span className="text-base font-semibold">Kiválasztott időszak:</span>
                        <span className="text-base font-semibold">{moment(startDate).format("YYYY. MM. DD.")} - {moment(endDate).format("YYYY. MM. DD.")}</span>
                    </div>
                }
                {periodType === "month" &&
                    <div className="flex flex-col justify-center items-center gap-4">

                        <span className="text-base font-semibold">Hónap kiválasztása:</span>
                        <DatePicker
                        
                            selected={moment(startDate, 'YYYY-MM-DD').toDate()}
                            onChange={(date) => {
                                setStartDate(moment(date).startOf('month').format('YYYY-MM-DD'))
                                setEndDate(moment(date).endOf('month').format('YYYY-MM-DD'))
                            }}
                            minDate={new Date('2023-01-01')}
                            maxDate={new Date()}
                            locale="hu"
                            todayButton="Mai nap"
                            inline
                            disabled={true}
                            showMonthYearPicker
                            showFullMonthYearPicker
                            showPopperArrow={false}
                        />

                    </div>
                }
                {periodType === "pastMonth" &&
                    <div className="flex flex-col justify-center items-center gap-4">
                        <span className="text-base font-semibold">Kiválasztott időszak:</span>
                        <span className="text-base font-semibold">{moment(startDate).format("YYYY. MM. DD.")} - {moment(endDate).format("YYYY. MM. DD.")}</span>
                    </div>
                }
                {periodType === "currentYear" &&
                    <div className="flex flex-col justify-center items-center gap-4">
                        <span className="text-base font-semibold">Kiválasztott időszak:</span>
                        <span className="text-base font-semibold">{moment(startDate).format("YYYY. MM. DD.")} - {moment(endDate).format("YYYY. MM. DD.")}</span>
                    </div>
                }
                {periodType === "year" &&
                    <div className="flex flex-col justify-center items-center gap-4">

                        <span className="text-base font-semibold">Év kiválasztása:</span>
                        <DatePicker
                        
                            selected={moment(startDate, 'YYYY-MM-DD').toDate()}
                            onChange={(date) => {
                                
                                setStartDate(moment(date).startOf('year').format('YYYY-MM-DD'))
                                setEndDate(moment(date).endOf('year').format('YYYY-MM-DD'))
                            }}
                            minDate={new Date('2023-01-01')}
                            maxDate={new Date()}
                            locale="hu"
                            todayButton="Mai nap"
                            inline
                            disabled={true}
                            showYearPicker
                        />

                    </div>
                }
                {periodType === "pastYear" &&
                    <div className="flex flex-col justify-center items-center gap-4">
                        <span className="text-base font-semibold">Kiválasztott időszak:</span>
                        <span className="text-base font-semibold">{moment(startDate).format("YYYY. MM. DD.")} - {moment(endDate).format("YYYY. MM. DD.")}</span>
                    </div>
                }
            </div>
        </div>
    );
}

interface PeriodTypeButtonProps {
    title: string;
    subTitle: string;
    type: StatPeriodType;
    selected?: boolean;
    onSelect: (type: StatPeriodType) => void;
    disabled?: boolean;
}

const PeriodTypeButton: FC<PeriodTypeButtonProps> = ({ title, subTitle, type, selected, onSelect, disabled }) => {
    return (
        <div className={twMerge(
            "flex flex-col flex-1  p-2 rounded-md border-[1px] justify-center items-center cursor-pointer",
            disabled ? "bg-gray-400/10" : selected ? "bg-blue-300" : "bg-blue-400/10",
            disabled ? "border-gray-400" : "border-blue-400"
        )}
            onClick={() => {
                if (!disabled) onSelect(type)
            }}
        >
            <span
                className={twMerge(
                    "text-base font-semibold text-center",
                    disabled ? "text-gray-400" : "text-black"
                )}
            >
                {title}
            </span>
            <span
                className={twMerge(
                    "text-sm font-light text-center",
                    disabled ? "text-gray-400" : "text-black"
                )}
            >
                {subTitle}
            </span>
        </div>
    )
}

export default PeriodSelector;