// IMPORT -- react
import { FC, useState, useEffect } from 'react';
// IMPORT -- third party
import { twMerge } from 'tailwind-merge';
import moment from 'moment';
// IMPORT -- icons
import { HiOutlineDatabase } from 'react-icons/hi';
// IMPORT -- project
import { lastTimestampFormat } from '../../../../utils/formatters';

interface LatestStoredProps {
    value: string;
}

const LatestStored: FC<LatestStoredProps> = ({ value }) => {

    const time = lastTimestampFormat(value)
    const [iconColor, setIconColor] = useState<string>('text-violet-500')
    const [iconColorTimer, setIconColorTimer] = useState<NodeJS.Timeout | null>(null)

    useEffect(() => {



        if (iconColorTimer) {
            clearTimeout(iconColorTimer)
            setIconColorTimer(null)
        }

        async function updateIconColor() {
            setIconColor('text-violet-400')
            setIconColorTimer(setTimeout(() => {
                if (moment().diff(moment(new Date(value)), 'minutes') > 10 ) {
                    setIconColor('text-rose-500')
                } else {
                    setIconColor('text-violet-500')
                }
                

            }, 100))
        }

        if (value) updateIconColor()

        return () => {
            if (iconColorTimer) {
                clearTimeout(iconColorTimer)
                setIconColorTimer(null)
            }
        }

    }, [value])

    return (
        <div className='flex flex-row justify-start items-center gap-1'>
            <HiOutlineDatabase className={twMerge('text-xl', iconColor)} />
            <span className='font-light text-gray-600 text-sm min-w-[80px]'>
                {time}
            </span>
        </div>
    );

}

export default LatestStored;