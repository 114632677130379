import React, { useState } from "react";

// material-ui
import Button from '@material-ui/core/Button';

// icons
import CloseIcon from '@material-ui/icons/Close';

// project import
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Content from "../components/Content";
import { DrawerHeader } from "../components/Styled";

// Providers
import { SnackbarProvider } from 'notistack';
import { ClientsContextProvider } from "../store/ClientsContext";
import DashboardContextProvider from '../store/DashboardContext';
import CustomerContextProvider from "../store/CustomerContext";
import SocketContextProvider from "../store/SocketContext";
import NavigationContextProvider from '../store/NavigationContext';
import StyleContextProvider from "../store/StyleContext";

const MainPage = () => {

    const [drawerOpen, setDrawerOpen] = useState(false)

    const notistackRef = React.createRef();
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    }

    return (
        <SnackbarProvider
            maxSnack={3}
            ref={notistackRef}
            action={(key) => (
                <Button onClick={onClickDismiss(key)}>
                    <CloseIcon />
                </Button>
            )}>
            <SocketContextProvider>
                <NavigationContextProvider>
                    <CustomerContextProvider>

                        <DashboardContextProvider>

                            <ClientsContextProvider>
                                <StyleContextProvider>
                                    <div className="flex w-full h-full" style={{ backgroundColor: "rgb(247, 249, 252)" }}>

                                        <Header drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
                                        <Sidebar drawerOpen={drawerOpen} />
                                        <div className="flex flex-col w-full h-full overflow-hidden">
                                            <DrawerHeader />
                                            <Content />
                                        </div>
                                    </div>
                                </StyleContextProvider>
                            </ClientsContextProvider>

                        </DashboardContextProvider>
                    </CustomerContextProvider>
                </NavigationContextProvider>
            </SocketContextProvider>
        </SnackbarProvider >
    )
}

export default MainPage