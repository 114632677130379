import React from 'react';
// project imports
import { useCustomer, useCustomerLoading } from '../../store/CustomerContext';
import ListRowSite from './components/ListRowSite';
import ListRowClient from './components/ListRowClient/ListRowClient';

// ==============================|| CLIENTS ||============================== //

const Clients = () => {

    const customer = useCustomer();
    const loading = useCustomerLoading();

    if (loading) return (
        <div className="flex justify-center items-center w-full h-full p-3 overflow-auto bg-gray-100">
            <span>Letöltés...</span>
        </div>)

    return (
        <div key='wrapper' className="w-full h-full p-3 overflow-auto bg-gray-200">

            <div className='flex flex-1 flex-col rounded-md overflow-hidden bg-white shadow-lg'>
                {customer?.sites.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((site, index) => (
                    <React.Fragment key={'fragment-'+site.id}>
                        <ListRowSite key={site.id} site={site} />
                        {site.devices.map((device) => (device.clients.map((client, index) => (
                            <ListRowClient key={site.id+'-'+client.id} device={device} client={client} />
                        ))
                        ))}

                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}

export default Clients;