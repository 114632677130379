import React from "react";

// project import
import { useNavigation } from "../store/NavigationContext";
import Dashboard from "../modules/dashboard/index";
import DataExport from "../modules/dataexport/index";
import Statistics from "../modules/statistics";
import Diagnostic from "../modules/diagnostic/index";
import Clients from "../modules/clients/index";

const Content = () => {

    const page = useNavigation()

    if (page === 'dashboard') return <Dashboard />
    if (page === 'statistics') return <Statistics />
    if (page === 'dataexport') return <DataExport />
    if (page === 'diagnostic') return <Diagnostic />
    if (page === 'clients') return <Clients />
    return <div>A keresett modul nem található.</div>
}

export default Content