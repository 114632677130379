import axios from "axios";

axios.defaults.validateStatus = () => { return true }

axios.interceptors.request.use((config) => {
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axios;