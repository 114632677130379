import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, LinearProgress, Typography, Tooltip, IconButton, Divider, Grow } from '@mui/material';

// third-party
import ReactApexChart from 'react-apexcharts';
import moment from 'moment'

// icon
import DateRangeIcon from '@mui/icons-material/DateRange';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// project import
import { formatNumberThousandSpacing } from '../../../utils/utils'
import Comparison from '../../../components/Comparison';
import MainCard from '../../../components/MainCard';
import { useStyle } from '../../../store/StyleContext';
import { useWeekMonthChart, useWeekMonthChartDate, useSetWeekMonthChartDate } from '../../../store/DashboardContext';
import utils from '../../../utils/utils';
import LoadingOverlay from '../../../components/LoadingOverlay';
import WeekStepper from './PeriodChart.WeekStepper';
import MonthStepper from './PeriodChart.MonthStepper';

// ==============================|| WEEK/MONTH BAR CHART ||============================== //

const WeekMonthChart = () => {

    const styleTheme = useStyle()
    const primaryColor = styleTheme.colors.primary
    const secondColor = styleTheme.colors.secondary

    const theme = useTheme();
    const { secondary } = theme.palette.text;
    const line = theme.palette.divider;

    const prod = primaryColor //"#015900a0"//theme.palette.primary[700]; //termelés
    const used = secondColor //"#8f0101"//theme.palette.primary.main; //fogyasztás

    //const [slot, setSlot] = useState('week');

    const divider = 1000;
    const unit = 'kWh';

    const [weekMonthConsSum, setWeekMonthConsSum] = useState(null);
    const [weekMonthProdSum, setWeekMonthProdSum] = useState(null);
    const [weekMonthConsUnit, setWeekMonthConsUnit] = useState('kWh');
    const [weekMonthProdUnit, setWeekMonthProdUnit] = useState('kWh');

    const [loading, setLoading] = useState(true)
    const [options, setOptions] = useState(null)
    const [series, setSeries] = useState(null);

    // ===== Context =====
    const context = useWeekMonthChart()
    const weekMonthData = context.data
    const weekMonthLoading = context.loading
    //const weekMonthError = dashboardContext.weekMonthChart.state.error
    const weekMonthDate = useWeekMonthChartDate()
    const setWeekMonthDate = useSetWeekMonthChartDate() // { start: '2023-3-6', end: '2023-3-12' }

    //const slot = dashboardContext.weekMonthChart.slot
    //const setSlot = dashboardContext.weekMonthChart.setSlot

    const [slot, setSlot] = useState('week')
    const [refresh, setRefresh] = useState(false)
    const [fetched, setFetched] = useState(weekMonthData ? true : false)

    // ===== Hónap / Hét váltás =====
    const handleSelectSlot = (selectedSlot) => {
        if (selectedSlot === 'week') {
            const start = moment().startOf('isoWeek').format('YYYY-MM-DD');
            const end = moment().endOf('isoWeek').format('YYYY-MM-DD');
            setWeekMonthDate({ start: start, end: end });
            setSlot('week');
        }
        if (selectedSlot === 'month') {
            const start = moment().startOf('month').format('YYYY-MM-DD');
            const end = moment().endOf('month').format('YYYY-MM-DD');
            setWeekMonthDate({ start: start, end: end });
            setSlot('month');
        }
    }
    
    useEffect(() => {

        if (!weekMonthData) setFetched(false)
        if (weekMonthLoading) setRefresh(true)

        if (!weekMonthLoading && weekMonthData) {

            const prodEnergyValues = weekMonthData.map(i => i.prodEnergy);
            const prodEnergySum = utils.roundTwoDecimals(utils.dividing(prodEnergyValues.reduce((partialSum, a) => partialSum + a, 0), 1000));
            const consEnergyValues = weekMonthData.map(i => i.consEnergy);
            const consEnergySum = utils.roundTwoDecimals(utils.dividing(consEnergyValues.reduce((partialSum, a) => partialSum + a, 0), 1000));
            setWeekMonthProdSum(prodEnergySum);
            setWeekMonthConsSum(consEnergySum);
            setWeekMonthProdUnit(unit);
            setWeekMonthConsUnit(unit);

            const startDate = moment(weekMonthDate.start);
            const endDate = moment(weekMonthDate.end);

            const dates = utils.enumDaysBetweenDates(startDate, endDate);

            function xaxis() {
                if (slot === 'month') {
                    const cat = dates
                    const xAxisLabelColors = Array(cat.length).fill(secondary)
                    return {
                        categories: cat,
                        labels: {
                            style: {
                                colors: xAxisLabelColors
                            },
                            formatter: function (value) {
                                return moment(value).format('MM.DD');
                            }
                        },
                        axisBorder: {
                            show: true,
                            color: line
                        },
                        tickAmount: cat.length
                    }
                }

                if (slot === 'week') {
                    const xAxisLabelColors = Array(7).fill(secondary)
                    const dayNames = ['Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat', 'Vasárnap']

                    const cat = dates.map((item, index) => {
                        const dayDate = moment(item).format('MM.DD')
                        const dayName = dayNames[index]
                        return `${dayName} (${dayDate})`
                    })

                    return {
                        categories: cat,
                        labels: {
                            style: {
                                colors: xAxisLabelColors
                            }
                        },
                        axisBorder: {
                            show: true,
                            color: line
                        },
                        tickAmount: 7
                    }
                }
            }

            function tooltipX(value) {
                if (slot === 'month') {
                    return moment(value).format('YYYY.MM.DD.');
                }
                if (slot === 'week') {
                    return value;
                }
            }

            const prodData = dates.map(date => {
                const value = weekMonthData.find(i => i.date === moment(date).format('YYYY-MM-DD'))
                if (!value) return 0
                return utils.roundTwoDecimals(utils.dividing(value.prodEnergy, divider))
            })
            const consData = dates.map(date => {
                const value = weekMonthData.find(i => i.date === moment(date).format('YYYY-MM-DD'))
                if (!value) return 0
                return utils.roundTwoDecimals(utils.dividing(value.consEnergy, divider))
            })

            setSeries([
                { name: 'Termelés', data: prodData },
                { name: 'Fogyasztás', data: consData }
            ])

            setOptions({
                chart: {
                    height: 450,
                    type: 'bar',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                colors: [prod, used],
                xaxis: xaxis(),
                yaxis: {
                    labels:
                    {
                        style:
                        {
                            colors: [secondary]
                        },
                        formatter: (value) => {
                            return Math.round(value)
                        },
                    }
                },
                grid: { strokeDashArray: 0, borderColor: line },
                tooltip: {
                    x: {
                        formatter(value) {
                            return tooltipX(value)
                        }
                    },
                    y: {
                        formatter(val) {
                            return `${formatNumberThousandSpacing(val)} ${unit}`;
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        if (val > 0) return `${formatNumberThousandSpacing(val)} ${unit}`;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '12px'
                    },
                    background: {
                        enabled: true,
                        color: '#fff',
                        foreColor: '#111',
                        padding: 4,
                        borderRadius: 2,
                        borderWidth: 1,
                        borderColor: primaryColor,
                        opacity: 0.9,
                    },
                },
            });
            setRefresh(false)
            setFetched(true)
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [weekMonthData, weekMonthLoading])

    return (
        <Grow in={true}>
            <MainCard content={false} loading={refresh}>
                {(refresh || !fetched) && <LoadingOverlay transparent={fetched} />}
                <Box sx={{ position: 'relative' }}>
                    <Grid container alignItems="center" rowGap={2} columnGap={2} justifyContent="space-between" sx={{ p: 1.5 }}>
                        <Grid item>
                            <Typography variant="h5">Napi összes energia</Typography>
                        </Grid>

                        <Grid item>
                            {!fetched ? <div></div>
                                : <Comparison
                                    firstText={`${formatNumberThousandSpacing(weekMonthProdSum)} ${weekMonthProdUnit}`}
                                    secondText={`${formatNumberThousandSpacing(weekMonthConsSum)} ${weekMonthConsUnit}`}
                                    firstTooltip="Napelemek termelése"
                                    secondTooltip="Rendszer fogyasztása"
                                    firstColor={primaryColor}
                                    secondColor={secondColor} />}

                        </Grid>
                        <Grid item>
                            <div className='dashboard-chart-controls'>
                                {slot === 'week' && <WeekStepper setWeekMonthDate={setWeekMonthDate} weekMonthLoading={refresh} />}
                                {slot === 'month' && <MonthStepper setWeekMonthDate={setWeekMonthDate} weekMonthLoading={refresh} />}
                                <Tooltip title="Havi nézet" arrow>
                                    <span>
                                        <IconButton size='small' onClick={() => { handleSelectSlot('month') }} disabled={refresh}>
                                            <CalendarMonthIcon sx={slot === 'month' ? { color: prod } : null} />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Heti nézet" arrow>
                                    <span>
                                        <IconButton size='small' onClick={() => { handleSelectSlot('week') }} disabled={refresh}>
                                            <DateRangeIcon sx={slot === 'week' ? { color: prod } : null} />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Box>
                        <Grid item>
                            <Box sx={{ pt: 1, pr: 2 }} style={{ height: 470 }} >
                                {(!loading && options !== null && series !== null) ?
                                    <ReactApexChart options={options} series={series} type="bar" height={450} style={{ filter: refresh ? 'blur(4px)' : 'none' }} /> :
                                    <div style={{ marginRight: '-20px', marginTop: '-10px' }}>
                                        <LinearProgress />
                                    </div>}
                            </Box>
                        </Grid>
                    </Box>
                </Box>

            </MainCard>
        </Grow>
    )
};

export default WeekMonthChart;



