// IMPORT -- react
import { useRef, useState } from 'react';
// IMPORT -- material-ui
import { Box, Menu, MenuItem, Toolbar, Tooltip, Stack, IconButton, Divider, Typography, Popover } from '@mui/material';
import { grey } from '@mui/material/colors';
// IMPORT -- third-party
import { twMerge } from 'tailwind-merge';
// IMPORT -- icons
import MenuIcon from '@mui/icons-material/Menu';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { HiSignal } from 'react-icons/hi2';
import { IoPeople, IoChevronForward, IoPerson, IoClose, IoNotifications, IoRefresh } from "react-icons/io5";
// IMPORT -- assets
import logo from '../assets/images/logo_white_transparent.png'
// IMPORT -- project
import { useUser, useCurrentCustomer, useSetCurrentCustomer, useLogout } from '../store/UserContext';
import { useMenu, useNavigation, useSetNavigation } from '../store/NavigationContext';
import { useCustomer, useCustomerLoading } from '../store/CustomerContext';
import { useSocketConnected } from '../store/SocketContext';
import { useRefresh } from '../store/DashboardContext';
import { AppBar } from './Styled';

//import { useLogout } from '../store/AuthContext';

const Header = (props) => {


   const user = useUser()
   const socketConnected = useSocketConnected()
   const refreshData = useRefresh()
   const navigation = useNavigation()

   const open = props.drawerOpen
   const setOpen = props.setDrawerOpen

   const setNavigation = useSetNavigation()
   //const contextDashboard = useDashboard()
   const logout = useLogout()
   const menu = useMenu()
   const customer = useCustomer()
   const currentCustomer = useCurrentCustomer()
   const customerLoading = useCustomerLoading()
   //const auth = useAuth()
   const changeCustomer = useSetCurrentCustomer()

   const customerSelectorContainerRef = useRef()

   const [anchorElNav, setAnchorElNav] = useState(null);
   const [anchorElUser, setAnchorElUser] = useState(null);
   const [anchorElCustomerSelector, setAnchorElCustomerSelector] = useState(null);

   const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
   const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
   const handleCloseNavMenu = () => setAnchorElNav(null);
   const handleCloseUserMenu = () => setAnchorElUser(null);

   //const loadingButtonSpin = contextDashboard.basic.loading;
   const loadingButtonSpin = false

   const handleCustomerSelectorClick = (event) => setAnchorElCustomerSelector(event.currentTarget);
   const handleCustomerSelectorClose = () => setAnchorElCustomerSelector(null);

   const customerSelectorOpen = Boolean(anchorElCustomerSelector);
   const customerSelectorId = customerSelectorOpen ? 'simple-popover' : undefined;

   function handleClickMenu(menu) {
      handleCloseNavMenu();
      setNavigation(menu)
   }

   return (
      <Box>
         <AppBar position="fixed" >
            <Toolbar>
               <IconButton
                  sx={{ marginRight: 2, display: { xs: 'none', md: 'flex' } }}
                  color="inherit"
                  edge="start"
                  onClick={() => setOpen(!open)}
               >
                  <MenuIcon />
               </IconButton>
               <Box sx={{ marginRight: 2, display: { xs: 'flex', md: 'none' } }}>
                  <IconButton color="inherit" edge="start" onClick={handleOpenNavMenu} >
                     <MenuIcon />
                  </IconButton>
                  <Menu
                     id="menu-appbar"
                     anchorEl={anchorElNav}
                     anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                     keepMounted
                     transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                     open={Boolean(anchorElNav)}
                     onClose={handleCloseNavMenu}
                     sx={{ display: { xs: 'block', md: 'none' } }}
                  >
                     {menu.map(item => {
                        if (item.type === 'divider') return <Divider key={item.number} />
                        if (item.type === 'menu') return (
                           <MenuItem key={item.number} onClick={() => handleClickMenu(item.module)}>
                              <Typography textAlign="center">{item.name}</Typography>
                           </MenuItem>
                        )
                        return null
                     })}
                  </Menu>
               </Box>

               <div className="flex flex-row gap-2 flex-1 items-center">
                  <img src={logo} alt="Logo" style={{ width: 40 }} />
                  <div className="flex-col h-full hidden lg:flex">
                     <div className="px-2 border-b-[1px] border-b-gray-100">
                        <p className="font-semibold">Smart Energy Solutions</p>
                     </div>
                     <div className="px-2">
                        <p>{customer ? customer.name : ''}</p>
                     </div>
                  </div>
                  <div className="hidden flex-col h-full sm:flex lg:hidden">
                     <div className="px-2 border-b-[1px] border-b-gray-100">
                        <p>{customer ? customer.name : ''}</p>
                     </div>
                     <div className="px-2">
                        <p>{user.displayName}</p>
                     </div>
                  </div>
               </div>

               <Stack direction="row" spacing={1.2} alignItems='center'>
                  <div className="hidden lg:flex border-r-[1px] border-r-white pr-2">
                     {user.displayName}
                  </div>
                  {user.customers.length > 1 &&
                     <div ref={customerSelectorContainerRef}>
                        <Tooltip title="Ügyfél váltás">
                           <div
                              className="cursor-pointer"
                              aria-describedby={customerSelectorId}
                              onClick={handleCustomerSelectorClick}>
                              <IoPeople size={25} className="text-gray-200" />
                           </div>
                        </Tooltip>
                        <Popover
                           id={customerSelectorId}
                           open={customerSelectorOpen}
                           anchorEl={anchorElCustomerSelector}
                           onClose={handleCustomerSelectorClose}
                           anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                           }}
                           container={customerSelectorContainerRef.current}
                           className="mt-3"
                        >
                           <div className="relative flex flex-col gap-0 p-2">
                              <div className="absolute top-2 right-2 group cursor-pointer" onClick={handleCustomerSelectorClose}>
                                 <IoClose size={22} className="text-gray-500 group-hover:text-gray-900 transition-colors" />
                              </div>
                              <div className="flex p-2">
                                 <span className="font-semibold">Ügyfelek:</span>

                              </div>
                              {user.customers.map((c, index) => {
                                 const isCurrentCustomer = currentCustomer === c.id ? true : false
                                 return (
                                    <div
                                       key={index}
                                       className="flex flex-row group cursor-pointer hover:bg-gray-100 p-2 rounded-md"
                                       onClick={() => {
                                          if (!isCurrentCustomer) changeCustomer(c.id)
                                          handleCustomerSelectorClose()
                                       }}
                                    >
                                       <div className="flex justify-center items-center">
                                          <div
                                             className={twMerge(
                                                "flex justify-center items-center w-[30px] h-[30px] rounded-full ring-1  bg-gray-200",
                                                !isCurrentCustomer ? 'ring-gray-200 group-hover:ring-orange-400' : 'ring-green-400'
                                             )}>
                                             <IoPerson size={22} className="text-gray-400" />
                                          </div>
                                       </div>
                                       <div className="flex flex-1 justify-start px-2 items-center">
                                          <span
                                             className={twMerge(
                                                "whitespace-nowrap transition-colors text-gray-500",
                                                isCurrentCustomer && 'text-gray-900'
                                             )}>
                                             {c.name}
                                          </span>
                                       </div>
                                       <div
                                          className="flex justify-center items-center w-[40px]"
                                       >
                                          <IoChevronForward
                                             size={22}
                                             className={twMerge(
                                                "group-hover:scale-125 transition text-gray-500",
                                                isCurrentCustomer && "hidden"
                                             )}
                                          />
                                       </div>
                                    </div>
                                 )
                              })}
                           </div>
                        </Popover>
                     </div>
                  }


                  <Tooltip title="Kapcsolat állapota">
                     <div
                        //className="cursor-pointer"
                        //aria-describedby={customerSelectorId}
                        onClick={() => { }}
                     >
                        <HiSignal size={25} className={twMerge(
                           socketConnected ? "text-green-300" : "text-rose-300"
                           )} />
                     </div>

                  </Tooltip>

                  <Tooltip title="Értesítések">
                     <div
                        className="cursor-pointer"
                        aria-describedby={customerSelectorId}
                        onClick={() => { }}
                     >
                        <IoNotifications size={25} className="text-gray-200" />
                     </div>

                  </Tooltip>

                  <Tooltip title="Adatok frissítése">
                     <div
                        className={twMerge(
                           navigation === 'dashboard' ? "cursor-pointer" : "cursor-not-allowed"
                           )}
                        aria-describedby={customerSelectorId}
                        onClick={() => { if (navigation === 'dashboard') refreshData() }}
                     //if (!loadingButtonSpin) contextDashboard.functions.refreshDashboard() }}
                     >
                        <IoRefresh
                           size={25}
                           className={twMerge(
                              "text-gray-200",
                              loadingButtonSpin ? 'spin' : 'spin paused',
                              navigation !== 'dashboard' ? 'text-gray-500' : ''
                           )}
                        />
                     </div>

                  </Tooltip>

                  <Tooltip title="Felhasználói menü">
                     <div
                        className="cursor-pointer"
                        aria-describedby={customerSelectorId}
                        onClick={handleOpenUserMenu}
                     >
                        <IoPerson
                           size={25}
                           className={twMerge(
                              "text-gray-200"
                           )}
                        />
                     </div>

                  </Tooltip>

                  <Menu
                     sx={{ mt: '45px' }}
                     anchorEl={anchorElUser}
                     anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                     keepMounted
                     transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                     open={Boolean(anchorElUser)}
                     onClose={handleCloseUserMenu}
                  >
                     <MenuItem onClick={() => {
                        logout()
                        handleCloseUserMenu()
                     }}>
                        <LogoutOutlinedIcon style={{ marginRight: 12, color: grey[600] }} />
                        <Typography textAlign="center">Kijelentkezés</Typography>
                     </MenuItem>
                  </Menu>
               </Stack>
            </Toolbar>
         </AppBar>
      </Box>
   )
}

export default Header

