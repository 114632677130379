// IMPORT -- react
import { useContext, useState, createContext, ReactNode, FC } from 'react';
// IMPORT -- project

const styleInitState = {
    colors: {
        primary: '#1976d2',
        secondary: '#d32f2f'
    }
}

const StyleContext = createContext(styleInitState);

export function useStyle() {
    return useContext(StyleContext);
}

interface StyleContextProviderProps {
    children: ReactNode;
}

const StyleContextProvider: FC<StyleContextProviderProps> = ({ children }) => {

    return (
        <StyleContext.Provider value={styleInitState}>
            {children}
        </StyleContext.Provider>
    )
}

export default StyleContextProvider;