import axios from './axios'
import { apiAuth } from '../store/AuthContext'

async function login(data) {

    return new Promise((resolve, reject) => {
        const { email, password, remember } = data
        axios.post('/api/login', { email: email, password: password, remember: remember, platform: "WEB" })
            .then(resp => {
                if (resp.status === 500) reject(new Error('Sikertelen kapcsolódás.'))
                if (resp.status !== 200) reject(new Error('Hibás Email cím vagy jelszó!'))
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

async function connect() {

    return new Promise((resolve, reject) => {
        axios.post('/api/connect', { _pid: localStorage.getItem('_pid') })
            .then(resp => {
                //if (resp.status === 200) 
                if (resp.status === 500) reject(new Error('Kapcsolódási hiba történt.'))
                resolve(resp.data)
            })
            .catch(err => {
                reject(new Error('Kapcsolódási hiba történt.'))
            })
    })
}

async function graphqlQuery(query, variables) {


    const { sid, access } = apiAuth

    return new Promise((resolve, reject) => {

        const data = variables ? { query, variables: variables } : query
        data._sid = sid
        axios.post('/api/query', data, { headers: { 'Authorization': 'Bearer ' + access } })
            .then(resp => {
                if (resp.status !== 200) reject(new Error('query error'))
                resolve(resp.data)
            })
            .catch(err => {

                reject(new Error('query error'))
            })
    })

}

async function apiPost(path, data) {

    const { sid, access } = apiAuth

    return new Promise((resolve, reject) => {

        data._sid = sid

        axios.post(`/api/${path}`, data, { headers: { 'Authorization': 'Bearer ' + access } })
            .then(resp => {
                if (resp.status !== 200) reject(new Error('Api error'))
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
    })

}


async function apiGet(path) {

    const { access } = apiAuth

    return new Promise((resolve, reject) => {

        axios.get(`/api/${path}`, { headers: { 'Authorization': 'Bearer ' + access } })
            .then(resp => {
                if (resp.status !== 200) reject(new Error('Api error'))
                resolve(resp.data)
            })
            .catch(err => {
                reject(err)
            })
    })

}

async function dataexport(data) {

    const { sid, access } = apiAuth

    return new Promise((resolve, reject) => {

        data._sid = sid

        axios.post('/api/export/xlsx', data, { headers: { 'Authorization': 'Bearer ' + access }, responseType: 'blob' })
            .then(resp => {
                if (resp.status !== 200) reject(new Error('Adatlekérési hiba történt.'))
                
                let headerLine = resp.headers['content-disposition'];
                let startFileNameIndex = headerLine.indexOf('"') + 1
                let endFileNameIndex = headerLine.lastIndexOf('"');
                let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

                const fileNameData = filename.split('.')
                const fileNameParts = fileNameData[0].split('_')
                fileNameParts.pop()
                const newFileName = fileNameParts.join('_').concat('.', fileNameData[1])

                resolve({ filename: newFileName, data: resp.data })
            })
            .catch(err => {
                reject(new Error('Adatlekérési hiba történt.'))
            })
    })
}



async function dataexportdata(data) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiPost('export/xlsxData', data)
            resolve(response)
        } catch (err) {
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

async function getCurrentCustomer(customerId) {
    return new Promise(async (resolve, reject) => {

        try {

            const query = `query DEVICE_TABLE($customerId: String!) 
            {customer(id: $customerId) { id name
                sites { id name 
                    clients { id name type { id name } }
                    groups { id name 
                        clients { id name type { id name } }
                    }
                } 
            } }`

            const variables = { customerId: customerId }
            const data = await graphqlQuery(query, variables)

            const result = data.data.customer

            resolve(result)
        } catch (err) {
            reject(new Error('Adatlekérdezési hiba történt.'))
        }

    })
}

async function getDashboardDevicesTable(customerId) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiPost('dashboard/devicesTable', { customerId: customerId })
            resolve(response)
        } catch (err) {
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

async function getDashboardDailyChart(customerId, date) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiPost('dashboard/dailyChart', { customerId: customerId, date: date })
            resolve(response)
        } catch (err) {
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

async function getDashboardDayYearCards(customerId) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiPost('dashboard/cards', { customerId: customerId })
            resolve(response)
        } catch (err) {
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

async function getDashboardAllTimeProd(customerId) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiPost('dashboard/cleenEnvironment', { customerId: customerId })
            resolve(response)
        } catch (err) {
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

async function getDashboardConsProdChart(customerId, startDate, endDate) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiPost('dashboard/weekMonthChart', { customerId: customerId, startDate: startDate, endDate: endDate })
            resolve(response)
        } catch (err) {
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

async function getDashboardCurrentStatus(customerId) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiPost('dashboard/currentPower', { customerId: customerId })
            resolve(response)
        } catch (err) {
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

async function getCustomer(customerId) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiPost('customer/getOne', { id: customerId })
            resolve(response)
        } catch (err) {
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

async function getRegister(token) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.post('/api/register/inspect', { token: token })
            
            if (response.status === 200) resolve(response.data)
            if (response.status === 400) reject(new Error(response.data))
            reject(new Error('Adatlekérdezési hiba történt.'))
        } catch (err) {
            console.log(err)
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

async function registerSend(id, session, token, password) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.post('/api/register/send', { id: id, session: session, token: token, password: password })
            
            if (response.status === 200) resolve(response.data)
            if (response.status === 400) reject(new Error(response.data))
            reject(new Error('Adatlekérdezési hiba történt.'))
        } catch (err) {
            console.log(err)
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

async function passwordReminderRequest(email) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.post('/api/register/reminder', { email: email })
            
            if (response.status === 200) resolve(response.data)
            if (response.status === 400) reject(new Error(response.data))
            reject(new Error('Adatlekérdezési hiba történt.'))
        } catch (err) {
            console.log(err)
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

async function diagnoscticsGetClients(customerId) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiPost('diagnostics/getClients', { customerId: customerId })
            resolve(response)
        } catch (err) {
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

async function diagnoscticsGetClientDataDay(clientId, date) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiPost('diagnostics/getClientDataDay', { clientId: clientId, date: date })
            resolve(response)
        } catch (err) {
            reject(new Error('Adatlekérdezési hiba történt.'))
        }
    })
}

const statistics = {
    getProperties: async (customerId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiPost('statistics/getProperties', { customerId: customerId })
                resolve(response)
            } catch (err) {
                reject(new Error('Adatlekérdezési hiba történt.'))
            }
        })
    },

    getData: async (collector, startDate, endDate, dataResolution, sumType) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiPost('statistics/getData', { 
                    collector: collector,
                    startDate: startDate,
                    endDate: endDate,
                    resolution: dataResolution,
                    sum: sumType
                })
                resolve(response)
            } catch (err) {
                reject(new Error('Adatlekérdezési hiba történt.'))
            }
        })
    }
}

const diagnostic = {

    getClientState: async (clientId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(`/api/diagnostic/state/client/${clientId}`)
                resolve(response)
            } catch (err) {
                reject(new Error('Adatlekérdezési hiba történt.'))
            }
        })
    }

}





const controller = {
    apiGet,
    apiPost,
    login,
    connect,
    dataexport,
    dataexportdata,
    graphqlQuery,
    getCurrentCustomer,
    getDashboardDevicesTable,
    getDashboardDailyChart,
    getDashboardDayYearCards,
    getDashboardAllTimeProd,
    getDashboardConsProdChart,
    getDashboardCurrentStatus,
    getCustomer,
    getRegister,
    registerSend,
    passwordReminderRequest,
    diagnoscticsGetClients,
    diagnoscticsGetClientDataDay,
    statistics,
    diagnostic
}

export default controller