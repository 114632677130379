// IMPORT -- react
import { FC } from 'react';

import { MdCheck } from "react-icons/md";
import { GiCheckMark } from "react-icons/gi";
import { GoAlert } from "react-icons/go";
import { useCurrentPower } from '../../../store/DashboardContext';
import LoadingOverlay from '../../../components/LoadingOverlay';
const SystemStatus: FC = () => {

    const context = useCurrentPower();
    const contextData = context.data;
    const contextLoading = context.loading;
    const contextError = context.error;

    return (
        <div className='relative flex flex-col gap-2 bg-white border-[1px] border-gray-200 rounded-lg overflow-hidden p-3'>
            {contextLoading && <LoadingOverlay transparent={false} />}
            <div className='flex flex-row justify-start items-center gap-2'>
                <div className='flex justify-center items-center bg-green-500 rounded-full w-10 h-10'>
                    <MdCheck className='text-white text-2xl' />

                </div>
                <span className='text-green-500'>Nincs riasztás</span>
            </div>

        </div>
    );
};

export default SystemStatus;