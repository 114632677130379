import React, { FC } from "react";
import { SourceItemType } from "../../types";

interface SourcesListProps {
    sources: SourceItemType[];
    onSelect: (id: string) => void;
}
const SourcesList: FC<SourcesListProps> = ({ sources, onSelect }) => {
    return (
        <div>
            {sources.map(source => {
                return (
                    <div
                    onClick={() => onSelect(source.id)}
                    >
                        {source.name}
                    </div>
                )
            })}
        </div>
    )
}

export default SourcesList