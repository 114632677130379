// react
import { FC, useEffect, useState } from 'react';

// third party
import { twMerge } from 'tailwind-merge';
import moment from 'moment';

// icons
import { MdLocationOn, MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

// project imports
import { SiteType, SiteDataType } from '../../../utils/types';
import { useSocket } from '../../../store/SocketContext'; 
import api from '../../../controllers/api/controller';

interface ListRowSiteProps {
    site: SiteType;
}

const ListRowSite: FC<ListRowSiteProps> = ({ site }) => {

    const socket = useSocket();

    const [status, setStatus] = useState<SiteDataType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [statusColor, setStatusColor] = useState<string>('text-orange-500')
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

    useEffect(() => {

        

        async function statusBlink() {
            setStatusColor('text-blue-400')
            setTimeout(() => {
                setStatusColor('text-blue-500')
            }, 100);
        }

        async function fetch() {
            setLoading(true)
            api.site.getStatus(site.id)
                .then((status) => {
                    if (status) {
                        setStatusColor('text-blue-500')
                        setStatus(status);
                        
                    } else {
                        setStatusColor('text-red-500')
                    }
                    subscribe()
                    setError(false);
                })
                .catch((error) => {
                    setStatusColor('text-red-500')
                    setError(true)
                })
                .finally(() => setLoading(false))
        }

        function subscribe() {
            let lastStatus: SiteDataType | null = null
            setTimer(setInterval(() => {
                statusBlink()
                if (lastStatus) {
                    
                    setStatus(lastStatus)
                }
            }, 3000));
            socket.emit('siteSubscribe', site.id)
            socket.on(site.id, (message: string) => {
                
                    
                    const messageObject = JSON.parse(message) as SiteDataType
                    lastStatus = messageObject
                
            })
        }

        fetch()


        return () => {
            socket.emit('siteUnsubscribe', site.id)
            if (timer) {
                clearInterval(timer);
            }
            
        }

    }, [])

    return (
        <div className='flex flex-col p-2 gap-1 border-b-[1px] border-b-gray-200 bg-gray-100/50'>
            <div className='flex flex-1 flex-row gap-2'>
                <div className='flex flex-row justify-center items-center'>
                    <MdLocationOn className={twMerge(
                        'text-xl',
                        statusColor
                    )} />
                </div>
                <div className='flex flex-1 flex-row justify-start items-center'>
                    <span className='font-semibold'>{site.name}</span>
                </div>


                <div className='flex flex-row gap-2 justify-start items-center'>
                    {loading &&
                        <div className='min-w-[103px] min-h-[24px] rounded-md bg-gray-200' />
                    }
                    {!loading && status &&
                        <>
                            <div className='flex flex-row min-w-[70px] justify-start items-center'>
                                <span className='font-semibold'>
                                    {powerFormat(status.power).value}
                                </span>
                            </div>
                            <div className='flex flex-row min-w-[25px] justify-start items-center'>
                                <span className='font-semibold'>
                                    {powerFormat(status.power).unit}
                                </span>
                            </div>
                        </>
                    }
                </div>

            </div>
            <div className='flex flex-1 flex-row gap-2'>

                <div className='flex flex-1 flex-row justify-start items-center'>
                    <span className='font-light text-gray-600 text-sm'>
                        {site.address}
                    </span>
                </div>
                {loading &&
                    <div className='min-w-[238px] min-h-[20px] rounded-md bg-gray-200' />
                }
                {!loading && status &&
                    <div className='flex flex-row gap-2 justify-start items-center'>
                        <div className='flex flex-row justify-center items-center'>
                            <MdArrowDropUp className='text-2xl text-green-500' />
                        </div>
                        <div className='flex flex-row min-w-[70px] justify-start items-center'>
                            <span className='font-light text-gray-600 text-sm'>
                                {powerFormat(status.powerProduction).value}
                            </span>
                        </div>
                        <div className='flex flex-row min-w-[25px] justify-start items-center'>
                            <span className='font-light text-gray-600 text-sm'>
                                {powerFormat(status.powerProduction).unit}
                            </span>
                        </div>
                        <div className='flex flex-row justify-center items-center'>
                            <MdArrowDropDown className='text-2xl text-red-500' />
                        </div>
                        <div className='flex flex-row min-w-[70px] justify-start items-center'>
                            <span className='font-light text-gray-600 text-sm'>
                                {powerFormat(status.powerConsumption).value}
                            </span>
                        </div>
                        <div className='flex flex-row min-w-[25px] justify-start items-center'>
                            <span className='font-light text-gray-600 text-sm'>
                                {powerFormat(status.powerConsumption).unit}
                            </span>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

function formatNumberThousandSpacing(num: number) {
    if (typeof num === 'number') return num.toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    else return num
}

function powerFormat(value: number): { value: string, unit: string } {

    if (!Math.abs(value)) return { value: '0,00', unit: 'W' }
    if (typeof Math.abs(value) !== 'number') return { value: '0,00', unit: 'W' }

    let sign = "+"
    if (value < 0) sign = "-"

    switch (true) {
        case (Math.abs(value) > 10000): return { value: sign + formatNumberThousandSpacing(Math.floor(Math.abs(value) / 10) / 100), unit: 'kW' }
        default: return { value: sign + formatNumberThousandSpacing(Math.floor(Math.abs(value) * 100) / 100), unit: 'W' }
    }

}

export default ListRowSite;