
// project imports
import api from './api';
import { InverterDataType, MeterDataType } from '../../utils/types';

type StatusResponseType = {
    status: InverterDataType | MeterDataType | null;
    lastStoredAt: Date | null;
}

async function getStatus(clientId: string) {
    return new Promise<StatusResponseType>((resolve, reject) => {
            api.get(`client/${clientId}/status`)
            .then((response) => {
                resolve(response as StatusResponseType)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const controller = { getStatus }
export { controller as default, getStatus }