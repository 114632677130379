// IMPORT -- react
import { FC, useState, useEffect } from 'react';
// IMPORT -- third party
import { twMerge } from 'tailwind-merge';
// IMPORT -- icons
import { BiTrendingDown, BiTrendingUp } from 'react-icons/bi';
// IMPORT -- project components
import { useCards } from '../../../store/DashboardContext';
import { energyFormat } from '../../../utils/formatters';
import LoadingOverlay from '../../../components/LoadingOverlay';
import utils from '../../../utils/utils';

interface EnergyCardProps {
    variant: 'consumption-year' | 'consumption-day' | 'production-year' | 'production-day';
}

const EnergyCard: FC<EnergyCardProps> = ({ variant }) => {

    const context = useCards()
    const contextLoading = context.loading;
    const contextData = context.data;
    const contextError = context.error;

    const [refresh, setRefresh] = useState<boolean>(false);
    const [fetched, setFetched] = useState<boolean>(false);
    const [valueMain, setValueMain] = useState<number>(0);
    const [valuePrev, setValuePrev] = useState<number>(0);
    const [valuePrevPeriod, setValuePrevPeriod] = useState<number>(0);
    const [valueTrending, setValueTrending] = useState<number>(0);

    useEffect(() => {

        if (!contextData) setFetched(false);
        if (contextLoading) setRefresh(true);

        if (!contextLoading && contextData) {
            switch (variant) {
                case 'consumption-year':
                    setValueMain(contextData.year.currentCons);
                    setValuePrev(contextData.year.prevCons);
                    setValuePrevPeriod(contextData.year.prevIntCons);
                    setValueTrending(utils.relDiff(contextData.year.currentCons || 0, contextData.year.prevIntCons || 0));
                    break;
                case 'consumption-day':
                    setValueMain(contextData.day.currentCons);
                    setValuePrev(contextData.day.prevCons);
                    setValuePrevPeriod(contextData.day.prevIntCons);
                    setValueTrending(utils.relDiff(contextData.day.currentCons || 0, contextData.day.prevIntCons || 0));
                    break;
                case 'production-year':
                    setValueMain(contextData.year.currentProd);
                    setValuePrev(contextData.year.prevProd);
                    setValuePrevPeriod(contextData.year.prevIntProd);
                    setValueTrending(utils.relDiff(contextData.year.currentProd || 0, contextData.year.prevIntProd || 0));
                    break;
                case 'production-day':
                    setValueMain(contextData.day.currentProd);
                    setValuePrev(contextData.day.prevProd);
                    setValuePrevPeriod(contextData.day.prevIntProd);
                    setValueTrending(utils.relDiff(contextData.day.currentProd || 0, contextData.day.prevIntProd || 0));
                    break;
            }

            setRefresh(false);
            setFetched(true);
        }

    }, [contextLoading, contextError, contextData, variant]);

    const title = () => {
        switch (variant) {
            case 'consumption-year': return 'Éves fogyasztás';
            case 'consumption-day': return 'Napi fogyasztás';
            case 'production-year': return 'Éves termelés';
            case 'production-day': return 'Napi termelés';
        };
    };

    const trendingColor = () => {
        switch (variant) {
            case 'consumption-year':
            case 'consumption-day': return 'bg-rose-500';
            case 'production-year':
            case 'production-day': return 'bg-green-500';
        };
    };

    const subValueTextColor = () => {
        switch (variant) {
            case 'consumption-year':
            case 'consumption-day': return 'text-rose-500';
            case 'production-year':
            case 'production-day': return 'text-green-500';
        };
    };

    const subTitlePeriod = () => {
        switch (variant) {
            case 'consumption-year':
            case 'consumption-day': return 'Előző időszak fogyasztása:';
            case 'production-year':
            case 'production-day': return 'Előző időszak termelése:';
        };
    };

    const subTitleFull = () => {
        switch (variant) {
            case 'consumption-year': return 'Előző év teljes fogyasztása:';
            case 'consumption-day': return 'Előző nap teljes fogyasztása:';
            case 'production-year': return 'Előző év teljes termelése:';
            case 'production-day': return 'Előző nap teljes termelése:';
        };
    };

    return (
        <div className='relative flex flex-col gap-2 w-full bg-white border-[1px] border-gray-200 rounded-lg p-3 overflow-hidden'>
            {(refresh || !fetched) && <LoadingOverlay transparent={fetched} />}
            <span className='text-gray-500'>
                {title()}
            </span>
            <div className='flex flex-row gap-4'>
                <span className='text-2xl font-bold'>{energyFormat(valueMain).value} {energyFormat(valueMain).unit}</span>
                <div className={twMerge(
                    'flex flex-row justify-center items-center gap-2 pt-0 pb-0 pl-4 pr-4 rounded-full',
                    trendingColor()
                )}>
                    {valueTrending < 0 && <BiTrendingDown className='text-white' />}
                    {valueTrending > 0 && <BiTrendingUp className='text-white' />}
                    <span className='text-white text-lg font-semibold'>{valueTrending} %</span>
                </div>
            </div>
            <div className='flex flex-row gap-2'>
                <span className='text-sm text-gray-400'>
                    {subTitlePeriod()}
                </span>
                <span className={twMerge(
                    'text-sm font-semibold',
                    subValueTextColor()
                )}>
                    {energyFormat(valuePrevPeriod).value} {energyFormat(valuePrevPeriod).unit}
                </span>
            </div>
            <div className='flex flex-row gap-2'>
                <span className='text-sm text-gray-400'>
                    {subTitleFull()}
                </span>
                <span className={twMerge(
                    'text-sm font-semibold',
                    subValueTextColor()
                )}>
                    {energyFormat(valuePrev).value} {energyFormat(valuePrev).unit}
                </span>
            </div>
        </div>
    );
};

export default EnergyCard;