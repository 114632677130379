import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { BsFillExclamationTriangleFill } from "react-icons/bs";

const ModuleErrorOverlay: FC = () => {
    return (
        <div className='absolute flex justify-center items-center top-0 left-0 w-full h-full z-50 bg-white'>
            <div className='flex flex-col justify-center items-center'>
            <BsFillExclamationTriangleFill className='text-amber-400 text-4xl' />
            <span className='text-sm font-light text-gray-300'>A modul betöltése sikertelen.</span>
            </div>
        </div>
    );
}

export default ModuleErrorOverlay;