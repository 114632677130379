// IMPORT -- react
import { FC, useEffect } from 'react';
// IMPORT -- material-ui
import { createTheme, ThemeProvider } from '@mui/material/styles';
// IMPORT -- project components
import { useSocket } from '../../store/SocketContext';
import { useCustomer } from '../../store/CustomerContext';
import { useUser } from '../../store/UserContext';
import NoCustomer from './components/NoCustomer';
import CustomerTitle from './components/CustomerTitle';
import Environment from './components/Environment';
import PowerStatus from './components/PowerStatus';
import SystemStatus from './components/SystemStatus';
import EnergyCard from './components/EnergyCard';
import DailyChart from './components/DailyChart';
import PeriodChart from './components/PeriodChart';

const theme = createTheme({
    palette: {
        primary: {
            light: "#42a5f5",
            main: "#1976d2",
            dark: "#1565c0"
        },
        /*
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
        */
    },
});

const Dashboard: FC = () => {

    const user = useUser();
    const socket = useSocket();
    const customer = useCustomer();

    useEffect(() => {

        let subscribedCustomerId: string;

        function subscribe(customerId: string) {
            subscribedCustomerId = customerId;
            socket.emit('customerSubscribe', customerId);
        }

        if (customer && socket) subscribe(customer.id);

        return () => {
            socket.emit('customerUnsubscribe', subscribedCustomerId);
        };

    }, [customer, socket]);

    if (user && user.customers.length === 0) return (<div className='p-3'><NoCustomer /></div>);

    return (
        <ThemeProvider theme={theme}>
            <div className='overflow-auto'>
                <div className='flex flex-col gap-3 p-3'>
                    <CustomerTitle />
                    <div className='grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-1 gap-3'>
                        <Environment />
                        <PowerStatus />
                        <SystemStatus />
                    </div>
                    <div className='grid 2xl:grid-cols-4 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 gap-3'>
                        <EnergyCard variant='consumption-year' />
                        <EnergyCard variant='consumption-day' />
                        <EnergyCard variant='production-year' />
                        <EnergyCard variant='production-day' />
                    </div>
                    <DailyChart />
                    <PeriodChart />
                </div>
            </div>
        </ThemeProvider>
    );
};

export default Dashboard;