// react
import React, { FC, useEffect, useState } from "react";

// material-ui
import { Skeleton, Box } from "@mui/material";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";

// third party npm
import { BeatLoader } from 'react-spinners';
import { twMerge } from "tailwind-merge";
import { SourceItemType, StatDividerType } from "../../../../types";
import ApexCharts from "apexcharts";
import { ImCalendar } from "react-icons/im";

// project components
import apiController from "../../../../controllers/api/controller";
import { formatNumberThousandSpacing, dividing, roundTwoDecimals } from "../../../../utils/utils";
import moment from "moment";

interface ResultProps {
    show: boolean;
    collector: SourceItemType[];
    startDate: string;
    endDate: string;
    dataResolution: string;
    dataSumType: boolean;
    dataDivider: StatDividerType;
}

type ResultSourceType = {
    id: string;
    name: string;
    type: string;
    color: string;
    prodEnergy: number;
}

const Result: FC<ResultProps> = ({ show, collector, startDate, endDate, dataResolution, dataSumType, dataDivider }) => {

    const [chart, setChart] = useState<ApexCharts | null>(null);
    const [resultSources, setResultSources] = useState<ResultSourceType[] | []>([]);
    const [resultLoading, setResultLoading] = useState<boolean>(true);

    const dataUnit = () => {
        if (dataDivider === 1000) return "kWh"
        if (dataDivider === 1000000) return "mWh"
        return "Wh"
    }

    const formatter = () => {
        switch (dataResolution) {
            case ('week'): return 'MM. dd.';
            case ('currentMonth'):
            case ('month'):
            case ('pastMonth'): return 'dd';
            case ('day'):
            case ('pastday'): return 'HH:mm';
            case ('currentYear'):
            case ('year'):
            case ('pastYear'): return 'M'
        }
    }

    const labelFormat = formatter();

    const chartOptions = {
        chart: {
            height: 450,
            type: 'bar',
            stacked: false,
            toolbar: {
                show: false,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: true,
                    customIcons: []
                },
            },
        },
        grid: {
            padding: {
                top: 25,
                right: 25,
                bottom: 25,
                left: 25
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 3,
            curve: 'smooth'
        },
        series: [],
        xaxis: {
            hideOverlappingLabels: false,
            type: 'datetime',
            //categories: ['1','2','3','4','5','6','7'],
            /*
            min: moment(dailyChartDate, 'YYYY-M-D').startOf('D').toDate().getTime(),
            max: moment(dailyChartDate, 'YYYY-M-D').endOf('D').toDate().getTime(),
            */
            axisBorder: {
                show: true,
            },
            labels: {
                datetimeUTC: false,
                format: labelFormat,
                //format: dataResolution === 'week' || dataResolution === 'currentMonth' ? 'yyyy-MM-dd' : 'HH:mm'
                //format: 'HH:mm',
            },
        },
        yaxis: {
            show: true,
            labels: {
                formatter: (value: number) => { return Math.round(value) },
            },

            title: {
                text: dataUnit(),
            }

        },
        tooltip: {
            x: {
                format: labelFormat,
                //format: dataResolution === 'week' || dataResolution === 'currentMonth' ? 'yyyy-MM-dd' : 'HH:mm'
                //format: 'HH:mm',
            },

            y: {
                formatter(val: null) {
                    if (val === null) return '-'
                    return `${formatNumberThousandSpacing(val)} ${dataUnit()}`;
                }
            }

        },
    }

    const sourceColor = (index: number): string => {
        const colors = ["#3b82f6", "#22c55e", "#f97316", "#ef4444", "#6366f1", "#d946ef", "#eab308", "#84cc16", "#a855f7", "#06b6d4"]
        return colors[index]
    }

    const periodTitle = () => {
        switch (dataResolution) {
            case ('day'): return moment(startDate).format('yyyy. MM. DD.');
            case ('pastday'): return moment(startDate).format('yyyy. MM. DD.');
            case ('week'): return moment(startDate).format('yyyy. MM. DD.') + ' - ' + moment(endDate).format('yyyy. MM. DD.');
            case ('currentMonth'): return moment(startDate).format('yyyy. MM. DD.') + ' - ' + moment(endDate).format('yyyy. MM. DD.');
            case ('month'): return moment(startDate).format('yyyy. MM. DD.') + ' - ' + moment(endDate).format('yyyy. MM. DD.');
            case ('pastMonth'): return moment(startDate).format('yyyy. MM. DD.') + ' - ' + moment(endDate).format('yyyy. MM. DD.');
            case ('currentYear'): return moment(startDate).format('yyyy. MM. DD.') + ' - ' + moment(endDate).format('yyyy. MM. DD.');
            case ('year'): return moment(startDate).format('yyyy. MM. DD.') + ' - ' + moment(endDate).format('yyyy. MM. DD.');
            case ('pastYear'): return moment(startDate).format('yyyy. MM. DD.') + ' - ' + moment(endDate).format('yyyy. MM. DD.');
            default: return '';
        }
    }

    useEffect(() => {

        const fetch = async () => {
            setResultLoading(true)
            const requestResolution = () => {
                switch (dataResolution) {
                    case ('week'):
                    case ('currentMonth'):
                    case ('pastMonth'):
                    case ('month'): return 'day';
                    case ('day'):
                    case ('pastday'): return '15min';
                    case ('currentYear'):
                    case ('year'):
                    case ('pastYear'): return 'month'
                    default: return null;
                }
            }
            const response = await apiController.statistics.getData(collector, startDate, endDate, requestResolution(), dataSumType) as any

           

            const sources: ResultSourceType[] = response.map((data: any, index: number) => {

                return {
                    id: data.id,
                    name: data.name,
                    type: data.sourceType,
                    color: sourceColor(index),
                    prodEnergy: data.data.map((i: any) => i.prodEnergy).reduce((accumulator: number, value: number) => { return accumulator + value }, 0)
                }
            })

            setResultSources(sources);

            const series = response.map((data: any) => {

               
                return {
                    name: data.name,
                    data: data.data.map((item: any) => { return [item.timestamp, roundTwoDecimals(dividing(item.prodEnergy, dataDivider))] })
                }

            })

           

            chartOptions.series = series;

            const newOptions = {
                ...chartOptions,
                colors: sources.map(s => s.color),

                series: series,

            }

            if (!chart) {
                const apChart = new ApexCharts(document.querySelector("#chart"), newOptions)
                setChart(apChart)
                apChart.render()
            } else {
                chart.destroy()
                const apChart = new ApexCharts(document.querySelector("#chart"), newOptions)
                setChart(apChart)
                apChart.render()
                //chart.updateOptions(newOptions, true, true)
            }


            setResultLoading(false)
        }

        if (show) {

            fetch()
        } else {
            if (chart) chart.destroy()
            setChart(null)
        }
        // eslint-disable-next-line
    }, [show])

    return (
        <div
            className={twMerge(
                "flex flex-1 flex-col gap-2 overflow-y-scroll overflow-x-hidden px-2 pb-2",
                show ? "flex" : "hidden"
            )}
        >
            <div className="relative flex flex-row w-full bg-white rounded-md border-[1px] border-gray-200">
                {resultLoading ?
                    <div className="absolute top-0 left-0  flex w-full h-full justify-center items-center bg-gray-100">
                        <BeatLoader />
                    </div>
                    : null}

                <div className="flex flex-col flex-1">
                    <div className="flex flex-row w-full p-2 pl-3 gap-2 justify-start items-center">
                        <ImCalendar className="w-4 h-4 text-[#233044]" />
                        
                        <span className="font-semibold">{periodTitle()}</span>
                    </div>
                    {resultSources.map((source, index) => {
                        return (
                            <div
                                key={source.id}
                                className={twMerge(
                                    "flex flex-row w-full ",
                                    "border-t-[1px] border-t-gray-200"

                                )}>
                                <div className="flex justify-center items-center p-2">
                                    <div
                                        className="flex w-5 h-5 rounded-md p-2"
                                        style={{ backgroundColor: source.color }}
                                    />
                                </div>
                                <div className="flex flex-row flex-1 justify-start items-center">
                                    <span>{source.name}</span>
                                </div>
                                <div className="flex flex-row flex-1 justify-between items-center px-4">
                                    <div className="flex flex-row gap-2">
                                        <TrendingUpIcon style={{ fontSize: '1.5rem', color: "#3b82f6" }} />
                                        <span className="text-base text-blue-700">
                                            {roundTwoDecimals(dividing(source.prodEnergy, dataDivider))}
                                        </span>
                                        <span className="text-base text-blue-700">
                                            {dataUnit()}
                                        </span>
                                    </div>
                                    <div className="flex flex-row gap-2">

                                        <span className="text-base text-red-600">
                                            0
                                        </span>
                                        <span className="text-base text-red-600">
                                            {dataUnit()}
                                        </span>
                                        <TrendingDownIcon style={{ fontSize: '1.5rem', color: "#ef4444" }} />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {!dataSumType &&
                        <div className="flex flex-row w-full border-t-[1px] border-t-gray-200">
                            <div className="flex justify-center items-center p-2">
                                <div
                                    className="flex w-5 h-5 rounded-md p-2"
                                    style={{ backgroundColor: "white" }}
                                />
                            </div>
                            <div className="flex flex-row flex-1 justify-start items-center">
                                <span className="text-base font-semibold">Összesítve</span>
                            </div>
                            <div className="flex flex-row flex-1 justify-between items-center px-4">
                                <div className="flex flex-row gap-2">
                                    <TrendingUpIcon style={{ fontSize: '1.5rem', color: "#3b82f6" }} />
                                    <span className="text-base font-semibold text-blue-700">
                                        {roundTwoDecimals(dividing(resultSources.map(i => i.prodEnergy).reduce((accumulator, value) => { return accumulator + value }, 0), dataDivider))}
                                    </span>
                                    <span className="text-base font-semibold text-blue-700">
                                        {dataUnit()}
                                    </span>
                                </div>
                                <div className="flex flex-row gap-2">

                                    <span className="text-base font-semibold text-red-600">
                                        0
                                    </span>
                                    <span className="text-base font-semibold text-red-600">
                                        {dataUnit()}
                                    </span>
                                    <TrendingDownIcon style={{ fontSize: '1.5rem', color: "#ef4444" }} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className={twMerge(
                "relative flex w-full bg-white rounded-md border-[1px] border-gray-200",
                !chart ? 'min-h-[50px]' : ''
                )}>
                {resultLoading ?
                    <div className="absolute top-0 left-0  flex w-full h-full justify-center items-center bg-gray-100 z-50 rounded-md">
                        <BeatLoader />
                    </div>
                    : null}
                <div id='chart' className="w-full"></div>
            </div>
        </div>
    )
}

export default Result;