import React, { useEffect, useState } from "react";
import { Dispatch, SetStateAction, FC } from "react";
import { twMerge } from "tailwind-merge"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

interface StepperProps {
    currentStep: number;
    setCurrentStep: Dispatch<SetStateAction<number>>;
    collected: number;
}

const Stepper: FC<StepperProps> = ({ currentStep, setCurrentStep, collected }) => {

    const [stages, setStages] = useState([
        { number: 1, label: 'Adatforrások', subLabel: 'kiválasztása', enabled: true },
        { number: 2, label: 'Időszak', subLabel: 'beállítása', enabled: false },
        { number: 3, label: 'Egyéb', subLabel: 'opciók', enabled: false },
        { number: 4, label: 'Lekérdezés', subLabel: '', enabled: false }
    ])

    useEffect(() => {
        if (collected > 0) {
            setStages(current => current.map(i => {
                return {...i, enabled: true}
            }))
        } else {
            setStages(current => current.map(i => {
                return {...i, enabled: i.number === 1 ? true : false}
            }))
        }
    }, [collected])

    return (
        <div className="flex flex-row justify-center items-center w-full gap-2 px-3 py-4">


            <div
                className="flex justify-center items-center w-[30px] h-[30px] p-2 rounded-full bg-gray-200 hover:bg-blue-300 cursor-pointer"
                onClick={() => {
                    if (1 !== currentStep && stages[currentStep - 1].enabled) setCurrentStep(currentStep - 1)
                }}
            >
                <AiOutlineArrowLeft />
            </div>


            {stages.map((stage, index) => {

                return (
                    <div
                        key={index}
                        className={twMerge(
                            "flex-row flex-1 items-center pr-2 cursor-pointer",
                            currentStep !== index + 1 ? "hidden md:flex" : "flex"
                            )}
                        onClick={() => {
                            if (stage.enabled) setCurrentStep(index + 1)
                        }}
                    >
                        <div
                            className={twMerge(
                                "flex items-center justify-center  w-[30px] h-[30px] p-2 rounded-full",
                                currentStep === index + 1 ? 'bg-blue-300' : 'bg-gray-200'
                            )}>
                            <span className={twMerge(
                                "",
                                stage.enabled ? "text-black" : "text-gray-500"
                            )}>
                                {stage.number}
                            </span>
                        </div>
                        <div
                            className="flex flex-col gap-0 leading-4 px-2 cursor-pointer"
                            onClick={() => {
                                if (stage.enabled) setCurrentStep(index + 1)
                            }}
                        >
                            <div className="m-0 p-0">
                                <span
                                    className={twMerge(
                                        "text-md font-semibold",
                                        stage.enabled ? "text-black" : "text-gray-400"
                                    )}
                                >
                                    {stage.label}
                                </span>
                            </div>
                            <div className="m-0 p-0">
                                <span
                                className={twMerge(
                                    "text-sm font-semibold",
                                    stage.enabled ? "text-black" : "text-gray-400"
                                    )}
                                >
                                    {stage.subLabel}
                                </span>
                            </div>
                        </div>
                        <div className="h-0 flex-1 border-b-[1px] border-gray-200 w-full" />
                    </div>
                )
            })}

            <div
                className="flex justify-center items-center w-[30px] h-[30px] p-2 rounded-full bg-gray-200 hover:bg-blue-300 cursor-pointer"
                onClick={() => {
                    if (stages.length !== currentStep && stages[currentStep].enabled) setCurrentStep(currentStep + 1)
                    
                }}
            >
                <AiOutlineArrowRight />
            </div>


        </div>
    )

}

export default Stepper;