// react
import React, { FC } from "react";

// third party npm
import { twMerge } from "tailwind-merge";

// project components
import { StatDividerType } from "../../../../types";

interface OptionsSelectorProps {
    show: boolean;
    dataSumType: boolean;
    setDataSumType: (type: boolean) => void;
    dataDivider: StatDividerType;
    setDataDivider: (divider: StatDividerType) => void;
}

const OptionsSelector: FC<OptionsSelectorProps> = ({ show, dataSumType, setDataSumType, dataDivider, setDataDivider }) => {
    return (

        <div className={twMerge(
            "flex flex-1 flex-row gap-2 overflow-auto px-2 pb-2",
            show ? "flex" : "hidden"
        )}>

            <div
                className={twMerge(
                    "bg-white h-full w-full justify-center items-center overflow-hidden border-[1px] border-gray-200 rounded-md",

                )}
            >
                <div className="flex flex-row w-full border-b-[1px] border-b-gray-200 p-4">
                    <span className="font-semibold text-base">Lekérdezés opciók</span>
                </div>
                <div className="flex flex-row w-full border-b-[1px] border-b-gray-200 p-4 gap-2 justify-start items-center">
                    <span>Lekérdezett adatok megjelenítése:</span>
                    <div className={twMerge(
                        "flex flex-col  p-2 rounded-md border-[1px] border-blue-400 justify-center items-center cursor-pointer",
                        !dataSumType ? "bg-blue-300" : "bg-blue-400/10"
                    )}
                        onClick={() => setDataSumType(false)}
                    >
                        <span>összehasonlítva</span>
                    </div>
                    <div className={twMerge(
                        "flex flex-col p-2 rounded-md border-[1px] border-blue-400 justify-center items-center cursor-pointer",
                        dataSumType ? "bg-blue-300" : "bg-blue-400/10"
                    )}
                        onClick={() => setDataSumType(true)}
                    >
                        <span>egysítve</span>
                    </div>
                </div>
                <div className="flex flex-row w-full border-b-[1px] border-b-gray-200 p-4 gap-2 justify-start items-center">
                    <span>Lekérdezett adatok mértékegysége:</span>
                    <div className={twMerge(
                        "flex flex-col  p-2 rounded-md border-[1px] border-blue-400 justify-center items-center cursor-pointer",
                        dataDivider === 1 ? "bg-blue-300" : "bg-blue-400/10"
                    )}
                        onClick={() => setDataDivider(1)}
                    >
                        <span>W</span>
                    </div>
                    <div className={twMerge(
                        "flex flex-col p-2 rounded-md border-[1px] border-blue-400 justify-center items-center cursor-pointer",
                        dataDivider === 1000 ? "bg-blue-300" : "bg-blue-400/10"
                    )}
                        onClick={() => setDataDivider(1000)}
                    >
                        <span>kW</span>
                    </div>
                    <div className={twMerge(
                        "flex flex-col p-2 rounded-md border-[1px] border-blue-400 justify-center items-center cursor-pointer",
                        dataDivider === 1000000 ? "bg-blue-300" : "bg-blue-400/10"
                    )}
                        onClick={() => setDataDivider(1000000)}
                    >
                        <span>MW</span>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default OptionsSelector;